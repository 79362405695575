import React, { Component } from 'react';
import LoadingModal from '../../components/modal/loading';
import Logo512x512 from '../../assets/img/logo-512x512.png';
import AuthController from './../../controllers/auth';

const authController = new AuthController()

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: {
        username: "",
        password: "",
      },
      showPassword: false,
      isLoading: false
    };
  }

  componentDidMount = () => {
    document.title = `Login | ${process.env.REACT_APP_NAME}`
    document.getElementById('body').classList.add('gray-bg')
  }

  handleChange = (field, value) => {
    let payload = this.state.payload
    payload[field] = value
    this.setState({ payload })
  }

  handleSubmit = async () => {
    this.setState({ isLoading: true })
    let payload = this.state.payload
    await authController.login(payload)
    this.setState({ isLoading: false })
  }

  render() {
    let payload = this.state.payload
    let showPassword = this.state.showPassword
    let isLoading = this.state.isLoading
    return (
      <div>
        <LoadingModal show={isLoading} />
        <div className="middle-box text-center loginscreen animated fadeInDown">
          <div className="ibox-content">
            <div>
              <img src={Logo512x512} alt="pengen bisa logo" style={{ height: 110, width: 110 }} />
            </div>
            <div className="form-group">
              <input type="email" className="form-control" placeholder="Email"
                onChange={(e) => this.handleChange('username', e.target.value)} value={payload.username} />
            </div>
            <div className="form-group">
              <div className="input-group m-b">
                <input type={showPassword ? "text" : "password"} className="form-control" placeholder="Password"
                  onChange={(e) => this.handleChange('password', e.target.value)} value={payload.password} />
                <div className="input-group-prepend" style={{ cursor: "pointer" }} onClick={() => this.setState({ showPassword: !showPassword })}>
                  <span className="input-group-addon">
                    {showPassword ? <i className="fa fa-eye-slash"></i> : <i className="fa fa-eye"></i>}
                  </span>
                </div>
              </div>
            </div>
            <button className="btn btn-primary block full-width m-b" onClick={() => this.handleSubmit()}><i className="fa fa-sign-in"></i> Login</button>
          </div>
          <hr />
          <div className="row" style={{ marginTop: -10 }}>
            <div className="col-6 text-left">
              <small><strong>Login Page</strong></small>
            </div>
            <div className="col-6 text-right">
              <small><strong>{process.env.REACT_APP_NAME}</strong> © {process.env.REACT_APP_YEAR}</small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
