import React, { Component } from 'react';
import Index from '../../index';
import Lottie from 'lottie-react-web';
import EmptyGif from '../../../assets/gif/empty-and-lost.json';
import ReportController from './../../../controllers/report';
import { Bar } from 'react-chartjs-2';
import FilterBooking from '../../../components/formFilter';

const reportController = new ReportController()

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      defaultPayload: {
        reportName: "report-user-by-month",
        booking_from: `${new Date().getFullYear()}-01-01`,
        booking_to: `${new Date().getFullYear()}-12-30`
      },
      dataMount: ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agu", "Sep", "Okt", "Nov", "Des"],
      dataUser: {
        labels: [],
        datasets: [
          {
            label: 'User',
            backgroundColor: 'rgba(26,179,148,0.2)',
            borderColor: 'rgba(26,179,148,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(26,179,148,0.4)',
            hoverBorderColor: 'rgba(26,179,148,1)',
            data: []
          }
        ]
      },
      dataTotalPendapatan: 0,
      dataPendapatan: {
        labels: [],
        datasets: [
          {
            label: 'Pendapatan (Rp.)',
            backgroundColor: 'rgba(26,179,148,0.2)',
            borderColor: 'rgba(26,179,148,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(26,179,148,0.4)',
            hoverBorderColor: 'rgba(26,179,148,1)',
            data: []
          }
        ]
      },
      dataBooking: {
        labels: [],
        datasets: [
          {
            label: 'Terbayar',
            backgroundColor: 'rgba(26,179,148,0.2)',
            borderColor: 'rgba(26,179,148,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(26,179,148,0.4)',
            hoverBorderColor: 'rgba(26,179,148,1)',
            data: []
          },
          {
            label: 'Menunggu',
            backgroundColor: 'rgba(248,172,89,0.2)',
            borderColor: 'rgba(248,172,89,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(248,172,89,0.4)',
            hoverBorderColor: 'rgba(248,172,89,1)',
            data: []
          }
        ]
      }
    };
  }

  componentDidMount = async () => {
    this.setState({ isLoading: true })
    let payload = JSON.parse(JSON.stringify(this.state.defaultPayload))
    this.setState({ payload })
    await this.getReport(payload)
    this.setState({ isLoading: false })
  }

  getReport = async (payload) => {
    let dataMount = this.state.dataMount
    payload.from = payload.booking_from
    payload.to = payload.booking_to
    // report user
    let response = await reportController.getReport(payload)
    if (response.status) {
      response = response.data
      let dataUser = this.state.dataUser
      dataUser.labels = []
      dataUser.datasets[0].data = []
      response.forEach(items => {
        dataUser.labels.push(`${dataMount[items.month - 1]} ${items.year.substring(2)}`)
        dataUser.datasets[0].data.push(parseInt(items.count))
      });
    }
    // report booking
    payload.reportName = 'report-booking-completed-by-month'
    response = await reportController.getReport(payload)
    if (response.status) {
      response = response.data
      let dataBooking = this.state.dataBooking
      let dataPendapatan = this.state.dataPendapatan
      let dataTotalPendapatan = 0
      dataBooking.labels = []
      dataBooking.datasets[0].data = []
      dataBooking.datasets[1].data = []

      dataPendapatan.labels = []
      dataPendapatan.datasets[0].data = []
      response.forEach(items => {
        dataBooking.labels.push(`${dataMount[items.month - 1]} ${items.year.substring(2)}`)
        dataBooking.datasets[0].data.push(parseInt(items.completed))
        dataBooking.datasets[1].data.push(parseInt(items.waiting))

        dataPendapatan.labels.push(`${dataMount[items.month - 1]} ${items.year.substring(2)}`)
        dataPendapatan.datasets[0].data.push(parseInt(items.total))

        dataTotalPendapatan = parseFloat(dataTotalPendapatan) + parseFloat(items.total)
      });
      this.setState({ dataTotalPendapatan })
    }
  }

  handleFilter = async (from = null) => {
    let payload = this.state.payload
    if (from && from === "cancel") {
      payload = JSON.parse(JSON.stringify(this.state.defaultPayload))
      this.setState({ payload })
    }
    this.setState({ isLoading: true })
    await this.getReport(payload)
    this.setState({ isLoading: false })
  }

  pageView = () => {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center m-t-lg" style={{ height: 300 }}>
            <Lottie options={{ animationData: EmptyGif }} />
            <div>Page not ready yet</div>
          </div>
        </div>
      </div>
    )
  }

  viewChartPeserta = () => {
    let { dataUser, payload, dataBooking, dataPendapatan, dataTotalPendapatan } = this.state
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox ">
            <div className="ibox-title">
              <h5 style={{ marginRight: 5 }}>{`Data Report Tanggal :`}</h5>
              <h5 style={{ color: "#f8ac59" }}>{`${payload.from} s/d ${payload.to}`}</h5>
            </div>
            <div className="ibox-content">
              <FilterBooking setting={["date"]}
                payload={this.state.payload}
                handleFilter={(from) => this.handleFilter(from)}
              />

              <div className="row" style={{
                marginLeft: 0, marginRight: 0, marginTop: 20, display: "flex",
                paddingTop: 10, paddingBottom: 10, border: "1px solid #1ab394", borderRadius: 5, color: "#1ab394"
              }}>
                <div className="col-lg-4">
                  <div style={{ color: "gray", marginRight: 10, fontWeight: "bold", fontSize: 10 }}>Total Pendapatan :</div>
                </div>
                <div className="col-lg-8" style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div style={{ fontWeight: "bold", fontSize: 20 }}>{this.getCurrency(dataTotalPendapatan, 'Rp')}</div>
                </div>
              </div>

              <div style={{ marginLeft: -10, marginRight: -10, marginTop: 20 }}>
                <div className="row">
                  <div className="col-lg-6" style={{ marginBottom: 20 }}>
                    {/* <h6>User Registrasi</h6> */}
                    <Bar
                      data={dataUser}
                      width={10}
                      height={250}
                      options={{
                        maintainAspectRatio: false
                      }}
                    />
                  </div>
                  <div className="col-lg-6" style={{ marginBottom: 20 }}>
                    {/* <h6>Booking</h6> */}
                    <Bar
                      data={dataBooking}
                      width={10}
                      height={250}
                      options={{
                        maintainAspectRatio: false
                      }}
                    />
                  </div>
                  <div className="col-lg-6" style={{ marginBottom: 20 }}>
                    {/* <h6>Pendapatan</h6> */}
                    <Bar
                      data={dataPendapatan}
                      width={10}
                      height={250}
                      options={{
                        maintainAspectRatio: false
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  getCurrency = (price, prefix) => {
    var number_string = price.toString().replace(/[^,\d]/g, '').toString(),
      split = number_string.split(','),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi),
      separator;

    if (ribuan) {
      separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
    return prefix === undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
  };

  render() {
    let { dataUser } = this.state
    return (
      <Index
        {...this.props}
        pageView={dataUser.labels.length > 0 ? this.viewChartPeserta() : this.pageView()}
        isLoading={this.state.isLoading}
      />
    );
  }
}
