import axios from 'axios';

export default async function fetchApi(method, path, body = null) {
  let api_url = process.env.REACT_APP_API_URL;
  let token = process.env.REACT_APP_API_KEY;
  try {
    let options = {
      method: method,
      url: api_url + path,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      }
    };

    if (body) options.data = body;
    if (token) options.headers['YTCI-API-KEY'] = `${token}`;

    let response = await axios(options).then(res => {
      return res.data
    });

    return response;
  } catch (err) {
    let error = { status: false, message: "Error to fetch api." };
    if (err.response && err.response.data) { error = err.response.data }
    return error;
  }
}