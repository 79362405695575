import React, { Component } from 'react';
import Index from '../../../index';
import Pagination from "react-js-pagination";
import DetailBooking from '../../../../components/modal/detailBookingEvent';
import FilterBooking from '../../../../components/formFilter';
import BookingController from '../../../../controllers/booking';
import FormReport from '../../../../components/formReport';

const bookingController = new BookingController()
const Swal = require('sweetalert2')

export default class Menunggu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      status: "Menunggu Pembayaran",
      payment: 0,
      payload: {},
      dataBooking: [],
      dataBookingLength: 0,
      pesertaNama: [],
      activePage: 1,
      takeData: 5,
      showDetail: false,
      dataDetail: null,
      dataNamaEvent: []
    };
  }

  componentDidMount = async () => {
    await this.getDataBooking(this.state.status)
    this.setState({ isLoading: false })
  }

  handlePageChange = async (pageNumber) => {
    let payload = this.state.payload
    this.setState({ isLoading: true, activePage: pageNumber });
    await this.getDataBooking(this.state.status, pageNumber, payload)
    this.setState({ isLoading: false })
  }

  getDataBooking = async (status, activePage = null, payload = {}) => {
    if (!activePage) activePage = this.state.activePage
    let take = this.state.takeData
    let skip = (activePage - 1) * take

    payload.skip = skip
    payload.take = take
    payload.status = status
    payload.get_event = "true"

    let dataNamaEvent = []
    let dataBooking = []
    let dataBookingLength = 0
    let pesertaNama = []
    let response = await bookingController.getBooking(payload)
    if (response.status) {
      dataBooking = response.data
      dataBookingLength = response.total_data

      dataBooking.forEach(items => {
        if(!dataNamaEvent.find(name => {return name === items.event_judul})){
          dataNamaEvent.push(items.event_judul)
        }
      });
    }
    response.peserta_full_nama.forEach(element => {
      pesertaNama.push(element.peserta_full_nama)
    });
    // console.log(response)
    this.setState({ dataBooking, dataBookingLength, pesertaNama, dataNamaEvent })
  }

  handleFilter = async (from = null) => {
    let status = this.state.status
    let activePage = this.state.activePage
    let dataBookingLength = this.state.dataBookingLength
    let takeData = this.state.takeData
    let payload = this.state.payload
    if (from && (from === "delete" || from === "update")) {
      let check = dataBookingLength % takeData;
      if (check === 1) activePage -= 1
    } else if (from && from === "cancel") {
      payload = {}
      this.setState({ payload })
    }
    this.setState({ isLoading: true })
    await this.getDataBooking(status, activePage, payload)
    this.setState({ isLoading: false })
  }

  handleVerification = (items) => {
    this.setState({ showDetail: false })
    let configInput = {
      title: "Verifikasi pembayaran?",
      text: "Masukan jumlah pembayaran!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#18a689",
      confirmButtonText: "Lanjut",
      input: 'number',
      preConfirm: (payment) => { this.setState({ payment }) }
    };

    let configConfirm = {
      title: `Sejumlah ${this.getCurrency(this.state.payment, "Rp. ")} ?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#18a689',
      confirmButtonText: 'Benar'
    };

    Swal.fire(configInput).then((result) => {
      if (result.value) {
        Swal.fire(configConfirm).then(async (result) => {
          if (result.value) {
            let payload = {
              id: items.booking_id,
              status: 'Terbayar',
              payment: this.state.payment,
            }
            this.setState({ isLoading: true })
            await bookingController.updateStatusBooking(payload)
            this.handleFilter("update")
          }
        })
      }
    });
  }

  handleDelete = (items) => {
    this.setState({ showDetail: false })
    let configConfirm = {
      title: "Lanjut untuk menghapus?",
      text: "Anda akan menghapus salah satu data!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#18a689",
      confirmButtonText: "Lanjut",
      preConfirm: (payment) => { this.setState({ payment }) }
    };

    Swal.fire(configConfirm).then(async (result) => {
      if (result.value) {
        this.setState({ isLoading: true })
        await bookingController.deleteBooking({ id: items.booking_id })
        this.handleFilter("delete")
      }
    });
  }

  handleDetailBooking = (dataDetail) => {
    // console.log(dataDetail)
    let booking_start = dataDetail.booking_start.split("/")
    dataDetail.booking_start = `${booking_start[2]}-${booking_start[1]}-${booking_start[0]}`
    this.setState({ dataDetail, showDetail: true })
  }

  getCurrency = (price, prefix) => {
    var number_string = price.toString().replace(/[^,\d]/g, '').toString(),
      split = number_string.split(','),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi),
      separator;

    if (ribuan) {
      separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
    return prefix === undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
  };

  viewItemBooking = () => {
    let dataBooking = this.state.dataBooking
    let dataBookingLength = this.state.dataBookingLength
    return (
      <div style={{ marginTop: 20 }} className="table-responsive">
        <table className="table table-hover table-bordered" style={{ fontSize: 12 }}>
          <thead>
            <tr>
              <th> No </th>
              <th> Nomor Hp </th>
              <th> Peserta </th>
              <th> Event </th>
              <th> Bayar (Rp)</th>
              <th> Action </th>
            </tr>
          </thead>
          <tbody>
            {
              dataBooking.map((items, keys) => (
                <tr key={keys}>
                  <td> {items.booking_id} </td>
                  <td> {items.peserta_nohp} </td>
                  <td> {items.peserta_full_nama} </td>
                  <td> {items.event_judul} </td>
                  <td className="text-right">
                    {items.event_harga - items.event_diskon === 0 ? "Free" : this.getCurrency(items.event_harga - items.event_diskon)}
                  </td>
                  <td style={{ width: 80 }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: 80 }}>
                      <button className="btn btn-xs btn-info btn-outline"
                        style={{ width: 25, height: 25 }} onClick={() => this.handleDetailBooking(items)}>
                        <i className="fa fa-info" />
                      </button>
                      <button className="btn btn-xs btn-primary btn-outline"
                        style={{ width: 25, height: 25 }} onClick={() => this.handleVerification(items)}>
                        <i className="fa fa-check" />
                      </button>
                      <button className="btn btn-xs btn-danger btn-outline"
                        style={{ width: 25, height: 25 }} onClick={() => this.handleDelete(items)}>
                        <i className="fa fa-trash" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            }
            {
              dataBooking.length === 0 &&
              <tr>
                <td colSpan="7" style={{ fontWeight: "bold", textAlign: 'center' }}> Data kosong </td>
              </tr>
            }
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {
            dataBookingLength !== dataBooking.length &&
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              hideDisabled
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.takeData}
              totalItemsCount={dataBookingLength}
              pageRangeDisplayed={3}
              onChange={this.handlePageChange.bind(this)}
            />
          }
        </div>
      </div>
    );
  }

  pageView = () => {
    let dataBookingLength = this.state.dataBookingLength
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox ">
            <div className="ibox-title" style={{ display: "flex", alignItems: "center" }}>
              <h5>{`Menunggu Pembayaran`}</h5>
              <h5 style={{
                marginLeft: 10, backgroundColor: "#f8ac59", padding: 5, borderRadius: 5,
                color: "#FFF", paddingLeft: 10, paddingRight: 10, position: "absolute", right: 10
              }}>{`${dataBookingLength} items`}</h5>
            </div>
            <div className="ibox-content">
              <FilterBooking setting={["noTransaction", "date", "peserta", "event"]}
                status={this.state.status}
                payload={this.state.payload}
                handleFilter={(from) => this.handleFilter(from)}
                dataPeserta={this.state.pesertaNama}
                dataNamaEvent={this.state.dataNamaEvent}
              />
              <FormReport
                setting={["booking_event"]}
                dataBooking={this.state.dataBooking}
                payload={this.state.payload}
                status={this.state.status}
              />
              {this.viewItemBooking()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <DetailBooking
          show={this.state.showDetail}
          onHide={() => this.setState({ showDetail: false })}
          data={this.state.dataDetail}
          handleVerification={(items) => this.handleVerification(items)}
          handleDelete={(items) => this.handleDelete(items)} />
        <Index {...this.props} pageView={this.pageView()} isLoading={this.state.isLoading} />
      </div>
    );
  }
}
