import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class Download extends React.Component {
  render() {
    let dataBooking = this.props.dataBooking
    let fileName = this.props.fileName
    return (
      <ExcelFile element={
        <button type="button" className="btn btn-w-m btn-primary" style={{ display: 'none' }} id="export-to-exel-btn">
          <span className="fa fa-file-excel-o" /> Export to Exel
        </button>
      } filename={fileName}>
        <ExcelSheet data={dataBooking} name="Employees">
          <ExcelColumn label="No Transaksi" value="booking_id" />
          <ExcelColumn label="Tanggal" value="booking_create" />
          <ExcelColumn label="Pama Peserta" value="peserta_full_nama" />
          <ExcelColumn label="Email Peserta" value="peserta_username" />
          <ExcelColumn label="Phone Number" value="peserta_nohp" />
          <ExcelColumn label="Nama Event" value="event_judul" />
          <ExcelColumn label="Nama Host" value="event_host" />
        </ExcelSheet>
      </ExcelFile>
    );
  }
}