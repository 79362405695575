import React from 'react';
import Modal from "react-bootstrap/Modal";

function DetailTrainer(props) {
  let data = props.data
  let trainer_image
  if (data) {
    if (data.trainer_image.includes('assets/foto')) {
      trainer_image = `https://www.pengenbisa.com/${data.trainer_image}`
    } else {
      trainer_image = data.trainer_image
    }
  }
  return (
    <Modal {...props} centered>
      {
        data &&
        <Modal.Body>
          <div style={{ margin: -10, marginTop: 10 }}>
            <div style={{}}>
              <div style={{ fontSize: 14, fontWeight: "bold" }}>Informasi Trainer</div>
              <div style={{
                border: "1px solid #CDCDCD", borderRadius: 5, padding: 10,
                paddingTop: 5, paddingBottom: 5, marginTop: 5, display: "flex",
                alignItems: "center"
              }}>
                <div>
                  <img src={trainer_image} alt="trainer pengen bisa"
                    style={{ height: 60, width: 60, borderRadius: 5, objectFit: "cover", overflow: 'hidden' }} />
                </div>
                <div style={{ marginLeft: 10 }}>
                  <div style={{ fontWeight: "bold" }}> {data.trainer_nama} </div>
                  <div style={{ fontSize: 11 }}> {data.trainer_nohp} </div>
                  <div style={{ fontSize: 11 }}> {data.trainer_username} </div>
                </div>
              </div>
            </div>
            <div style={{ backgroundColor: "#CDCDCD", height: 1, marginTop: 10, marginBottom: 10 }} />
            <div style={{ marginLeft: 5, marginRight: 5, display: "flex", justifyContent: "space-between" }}>
              <div style={{ fontSize: 12 }}>TANGGAL LAHIR</div>
              <div style={{ fontSize: 14, fontWeight: "bold" }}>{data.trainer_tgl || "-"}</div>
            </div>
            <div style={{ backgroundColor: "#CDCDCD", height: 1, marginTop: 10, marginBottom: 10 }} />
            <div style={{ marginLeft: 5, marginRight: 5, display: "flex", justifyContent: "space-between" }}>
              <div style={{ fontSize: 12 }}>TEMPAT LAHIR</div>
              <div style={{ fontSize: 14, fontWeight: "bold" }}>{data.trainer_tpl || "-"}</div>
            </div>
            <div style={{ backgroundColor: "#CDCDCD", height: 1, marginTop: 10, marginBottom: 10 }} />
            <div style={{ marginLeft: 5, marginRight: 5, display: "flex", justifyContent: "space-between" }}>
              <div style={{ fontSize: 12 }}>JENIS KELAMIN</div>
              <div style={{ fontSize: 14, fontWeight: "bold" }}>{data.trainer_jk || "-"}</div>
            </div>
            <div style={{ backgroundColor: "#CDCDCD", height: 1, marginTop: 10, marginBottom: 10 }} />
            <div style={{ marginLeft: 5, marginRight: 5, }}>
              <div style={{ fontSize: 12 }}>ALAMAT</div>
              <div style={{
                border: "1px solid #CDCDCD", borderRadius: 5, padding: 10,
                paddingTop: 5, paddingBottom: 5, marginTop: 5, display: "flex",
                alignItems: "center"
              }}>
                <div style={{}}>
                  <div style={{ fontSize: 11, textAlign: "justify" }}> {data.trainer_alamat || "-"} </div>
                </div>
              </div>
            </div>
            <div style={{ backgroundColor: "#CDCDCD", height: 1, marginTop: 10, marginBottom: 10 }} />
            <div style={{ marginLeft: 5, marginRight: 5, }}>
              <div style={{ fontSize: 12 }}>DESKRIPSI</div>
              <div style={{
                border: "1px solid #CDCDCD", borderRadius: 5, padding: 10,
                paddingTop: 5, paddingBottom: 5, marginTop: 5, display: "flex",
                alignItems: "center"
              }}>
                <div style={{}}>
                  <div style={{ fontSize: 11, textAlign: "justify" }}> {data.trainer_desc || "-"} </div>
                </div>
              </div>
            </div>
            <div style={{ backgroundColor: "#CDCDCD", height: 1, marginTop: 10, marginBottom: 10 }} />
            <div style={{ marginLeft: 5, marginRight: 5, }}>
              <div style={{ fontSize: 12 }}>MOTIVASI</div>
              <div style={{
                border: "1px solid #CDCDCD", borderRadius: 5, padding: 10,
                paddingTop: 5, paddingBottom: 5, marginTop: 5, display: "flex",
                alignItems: "center"
              }}>
                <div style={{}}>
                  <div style={{ fontSize: 11, textAlign: "justify" }}> {data.trainer_motivasi || "-"} </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: 30, marginLeft: -15, marginRight: -15 }}>
            <button type="button" className="btn btn-w-m btn-warning"
              style={{ width: "100%" }} onClick={() => props.handleEdit(data)}>
              <i className="fa fa-pencil" /> Edit Data
            </button>
          </div>
        </Modal.Body>
      }
      <Modal.Footer>
        <button type="button" className="btn btn-w-m btn-danger" onClick={() => props.onHide()}>
          <i className="fa fa-sign-out" /> Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default DetailTrainer
