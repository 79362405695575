import React, { Component } from 'react';
import Index from '../../index';
import { storage } from '../../../config/firebase';
import EventController from '../../../controllers/event';
import moment from 'moment';

const eventController = new EventController()

export default class FormEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      progress: 0,
      isEdit: false,
      dataTrainer: [],
      dataEvent: {
        event_judul: "",
        event_host: "",
        event_kuota: "",
        event_harga: "",
        event_diskon: "",
        event_image: "assets/foto/400x600.png",
        event_desc: "",
        event_batas_tf: moment().add(2, "day").format("YYYY-MM-DD") + " 00:00:00",
      }
    };
  }

  componentDidMount = async () => {
    const id = new URLSearchParams(this.props.location.search).get("id");
    this.getDataEvent(id)
  }

  getDataEvent = async (id) => {
    let payload = { take: 1, skip: 0 }
    if (id) payload.id = id
    let dataEvent = await eventController.getEvent(payload)
    // console.log(dataEvent)
    if (dataEvent.status) {
      if (id) {
        dataEvent = dataEvent.data[0]
        this.setState({ dataEvent, isEdit: true })
      }
    }
    this.setState({ isLoading: false })
  }

  handleSave = async () => {
    this.setState({ isLoading: true })
    let dataEvent = this.state.dataEvent
    dataEvent.event_harga = parseInt(dataEvent.event_harga.replace(/\D/g, ""))
    dataEvent.event_diskon = parseInt(dataEvent.event_diskon.replace(/\D/g, ""))
    let isEdit = this.state.isEdit

    // console.log(dataEvent)
    let response = {}
    if (isEdit) response = await eventController.updateEvent(dataEvent)
    else response = await eventController.addEvent(dataEvent)

    if (response.status) this.props.history.goBack()
    this.setState({ isLoading: false })
  }

  convertToSlug = (nameCourses, nameFaile) => {
    if (nameCourses === "") nameCourses = nameFaile
    return nameCourses.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-');
  }

  handleChange = (field, value) => {
    let dataEvent = this.state.dataEvent
    if (field === "event_image") {
      let url_name = this.convertToSlug(dataEvent.event_judul, value.name)
      const uploadTask = storage.ref(`event_image/${url_name}`).put(value)
      uploadTask.on("state_changed",
        snapshot => {
          const progress = Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100
          this.setState({ progress })
        }, error => {
          console.log(error)
        }, () => {
          storage.ref("event_image").child(url_name)
            .getDownloadURL().then(url => {
              dataEvent[field] = url
              this.setState({ dataEvent })
            })
        })
    } else {
      if(
        field === 'event_diskon' && 
        parseInt(value.replace(/\D/g, "")) > dataEvent['event_harga']
      ) {
        value = dataEvent['event_harga']
      }
      
      dataEvent[field] = value
      this.setState({ dataEvent })
    }
  }

  getCurrency = (price, prefix) => {
    var number_string = price.toString().replace(/[^,\d]/g, '').toString(),
      split = number_string.split(','),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi),
      separator;

    if (ribuan) {
      separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
    return prefix === undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
  };

  viewItemEvent = () => {
    let progress = this.state.progress
    let dataEvent = this.state.dataEvent
    let event_image = dataEvent.event_image
    if (event_image.includes('assets/foto')) {
      event_image = `https://www.pengenbisa.com/${event_image}`
    }
    return (
      <div>
        <div style={{ border: "1px solid #DCDCDC", borderRadius: 5, padding: 10 }}>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label><b>Judul</b></label>
                <input type="text" className="form-control" value={dataEvent.event_judul}
                  onChange={(e) => this.handleChange("event_judul", e.target.value)} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label><b>Penyelenggara</b></label>
                <input type="text" className="form-control" value={dataEvent.event_host}
                  onChange={(e) => this.handleChange("event_host", e.target.value)} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label><b>Harga (Rp)</b></label>
                <input type="text" className="form-control" value={this.getCurrency(dataEvent.event_harga, 'Rp. ')}
                  onChange={(e) => this.handleChange("event_harga", e.target.value)} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label><b>Diskon (Rp)</b></label>
                <input type="text" className="form-control" value={this.getCurrency(dataEvent.event_diskon, 'Rp.')}
                  onChange={(e) => this.handleChange("event_diskon", e.target.value)} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label><b>Jumlah Kuota</b></label>
                <input type="number" className="form-control" value={dataEvent.event_kuota}
                  onChange={(e) => this.handleChange("event_kuota", e.target.value)} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label><b>Batas Transfer</b></label>
                <input type="text" className="form-control" value={dataEvent.event_batas_tf}
                  onChange={(e) => this.handleChange("event_batas_tf", e.target.value)} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label><b>Deskripsi Event</b></label>
                <textarea className="form-control" value={dataEvent.event_desc} rows={6}
                  onChange={(e) => this.handleChange("event_desc", e.target.value)} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label><b>Foto</b></label>
                <div style={{ marginBottom: 5, marginTop: -5 }}>
                  <img alt="trainer" className="m-t-xs img-responsive" id="gambar_preview"
                    src={event_image}
                    style={{ height: 150, width: 100, borderRadius: 2, objectFit: "cover", overflow: 'hidden' }} />
                </div>

                <div className="progress progress-mini" style={{ marginBottom: 5 }}>
                  <div className="progress-bar progress-bar-primary" style={{ width: `${progress}%` }}
                    role="progressbar" aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100} />
                </div>

                <div className="custom-file">
                  <input id="foto" type="file" className="custom-file-input"
                    onChange={(e) => this.handleChange("event_image", e.target.files[0])} />
                  <label htmlFor="foto" className="custom-file-label">Choose file...</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
          <button type="button" className="btn btn-w-m btn-warning"
            onClick={() => this.props.history.goBack()}>
            <i className="fa fa-arrow-left" /> Kembali
          </button>
          <button type="button" className="btn btn-w-m btn-primary"
            style={{ marginLeft: 10 }} onClick={() => this.handleSave()}>
            <i className="fa fa-save" /> Save
          </button>
        </div>
      </div>
    )
  }

  pageView = () => {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox ">
            <div className="ibox-title">
              <h5>Form Event</h5>
            </div>
            <div className="ibox-content">
              {this.viewItemEvent()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <Index {...this.props} pageView={this.pageView()} isLoading={this.state.isLoading} />
    );
  }
}
