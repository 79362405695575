import React, { Component } from 'react';

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="pull-right">
          <strong>{process.env.REACT_APP_NAME}</strong> © {process.env.REACT_APP_YEAR}
        </div>
      </div>
    );
  }
}
