import React, { Component } from 'react';
import Index from '../../index';
import Pagination from "react-js-pagination";
import PesertaController from './../../../controllers/peserta';
import FilterBooking from '../../../components/formFilter';

const pesertaController = new PesertaController()
const Swal = require('sweetalert2')

export default class Peserta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      payload: {},
      dataPeserta: [],
      pesertaNama: [],
      dataPesertaLength: 0,
      activePage: 1,
      takeData: 5,
      showDetail: false,
      dataDetail: null
    };
  }

  componentDidMount = async () => {
    await this.getDataPeserta()
    this.setState({ isLoading: false })
  }

  getDataPeserta = async (activePage = null, payload = {}) => {
    if (!activePage) activePage = this.state.activePage
    let take = this.state.takeData
    let skip = (activePage - 1) * take

    payload.skip = skip
    payload.take = take

    let dataPeserta = []
    let pesertaNama = []
    let dataPesertaLength = 0
    let response = await pesertaController.getPeserta(payload)
    if (response.status) {
      dataPeserta = response.data
      dataPesertaLength = response.total_data
    }
    response.peserta_full_nama.forEach(element => {
      pesertaNama.push(element.peserta_full_nama)
    });
    // console.log(dataPeserta)
    this.setState({ dataPeserta, dataPesertaLength, pesertaNama })
  }

  handleFilter = async (from = null) => {
    let activePage = this.state.activePage
    let dataPesertaLength = this.state.dataPesertaLength
    let takeData = this.state.takeData
    let payload = this.state.payload
    if (from && (from === "delete")) {
      let check = dataPesertaLength % takeData;
      if (check === 1) activePage -= 1
    } else if (from && from === "cancel") {
      payload = {}
      this.setState({ payload })
    }
    this.setState({ isLoading: true })
    // console.log(payload)
    await this.getDataPeserta(activePage, payload)
    this.setState({ isLoading: false })
  }

  handleDelete = (items) => {
    this.setState({ showDetail: false })
    let configConfirm = {
      title: "Lanjut untuk hapus data?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#18a689",
      confirmButtonText: "Lanjut",
    };

    Swal.fire(configConfirm).then(async (result) => {
      if (result.value) {
        this.setState({ isLoading: true })
        await pesertaController.deletePeserta({ id: items.peserta_id })
        this.handleFilter("delete")
      }
    });
  }

  handleChangeStatus = (items) => {
    let dataPeserta = JSON.parse(JSON.stringify(items))
    let disabled = dataPeserta.peserta_aktif
    if (disabled === "0") disabled = "1"
    else disabled = "0"

    dataPeserta.id = parseInt(dataPeserta.peserta_id)
    dataPeserta.peserta_aktif = disabled

    this.setState({ showDetail: false })
    let configConfirm = {
      title: "Lanjut untuk mengubah status?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#18a689",
      confirmButtonText: "Lanjut",
    };

    Swal.fire(configConfirm).then(async (result) => {
      if (result.value) {
        this.setState({ isLoading: true })
        await pesertaController.updatePeserta(dataPeserta)
        this.handleFilter()
      }
    });
  }

  handlePageChange = async (pageNumber) => {
    let payload = this.state.payload
    this.setState({ isLoading: true, activePage: pageNumber });
    await this.getDataPeserta(pageNumber, payload)
    this.setState({ isLoading: false })
  }

  viewItemPeserta = () => {
    let dataPeserta = this.state.dataPeserta
    let dataPesertaLength = this.state.dataPesertaLength
    let activePage = this.state.activePage
    let takeData = this.state.takeData
    return (
      <div className="table-responsive" style={{ marginTop: 10 }}>
        <div>{`${dataPesertaLength} items`}</div>
        <table className="table table-hover table-bordered" style={{ fontSize: 12, marginTop: 10 }}>
          <thead>
            <tr>
              <th> No </th>
              <th> Nomor Hp </th>
              <th> Nama </th>
              <th> Email </th>
              <th> Status </th>
              <th> Action </th>
            </tr>
          </thead>
          <tbody>
            {
              dataPeserta.map((items, keys) => (
                <tr key={keys}>
                  <td> {keys + 1 + ((activePage - 1) * takeData)} </td>
                  <td> {items.peserta_nohp} </td>
                  <td> {items.peserta_full_nama} </td>
                  <td> {items.peserta_username} </td>
                  <td>
                    <div className="switch">
                      <div className="onoffswitch">
                        <input type="checkbox" checked={items.peserta_aktif === "0" ? false : true}
                          className="onoffswitch-checkbox" id={items.peserta_id}
                          onChange={() => this.handleChangeStatus(items)} />
                        <label className="onoffswitch-label" htmlFor={items.peserta_id}>
                          <span className="onoffswitch-inner" />
                          <span className="onoffswitch-switch" />
                        </label>
                      </div>
                    </div>
                  </td>
                  <td style={{ width: 55 }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: 55 }}>
                      <button className="btn btn-xs btn-info btn-outline" disabled={true}
                        style={{ width: 25, height: 25 }} onClick={() => this.setState({ dataDetail: items, showDetail: true })}>
                        <i className="fa fa-info" />
                      </button>
                      <button className="btn btn-xs btn-danger btn-outline"
                        style={{ width: 25, height: 25 }} onClick={() => this.handleDelete(items)}>
                        <i className="fa fa-trash" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            }
            {
              dataPeserta.length === 0 &&
              <tr>
                <td colSpan="7" style={{ fontWeight: "bold", textAlign: 'center' }}> Data kosong </td>
              </tr>
            }
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {
            dataPesertaLength !== dataPeserta.length &&
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              hideDisabled
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.takeData}
              totalItemsCount={dataPesertaLength}
              pageRangeDisplayed={3}
              onChange={this.handlePageChange.bind(this)}
            />
          }
        </div>
      </div>
    )
  }

  pageView = () => {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox ">
            <div className="ibox-title" style={{
              display: "flex", alignItems: "center", justifyContent: "space-between"
            }}>
              <h5>Daftar Peserta</h5>
              <button type="button" className="btn btn-w-m btn-primary" disabled={true}
                onClick={() => this.props.history.push("/trainer/form")}
                style={{ position: "absolute", right: 20 }}>
                <i className="fa fa-plus" /> Tambah Peserta
              </button>
            </div>
            <div className="ibox-content">
              <FilterBooking setting={["peserta", "date"]}
                payload={this.state.payload}
                handleFilter={(from) => this.handleFilter(from)}
                dataPeserta={this.state.pesertaNama} />
              {this.viewItemPeserta()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <Index {...this.props} pageView={this.pageView()} isLoading={this.state.isLoading} />
    );
  }
}
