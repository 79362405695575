import React, { Component } from 'react';
import Download from './report/reportBooking';
import DownloadEvent from './report/reportBookingEvent';
import BookingController from './../controllers/booking';
import _ from 'lodash';

const bookingController = new BookingController()

export default class FormReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      status: "",
      setting: {
        booking: false,
        booking_event: false
      },
      payload: {},
      dataBooking: []
    };
  }

  componentDidMount = async () => {
    this.handleCheckField()
  }

  handleCheckField = () => {
    let setting = this.state.setting
    this.props.setting.forEach(field => {
      setting[field] = true
    });
    this.setState({ setting })
  }

  handleExportTOExel = async () => {
    let { payload, status } = this.props
    let fileName = `Data ${status}`
    if (payload.booking_from) fileName += ` dari ${payload.booking_from}`
    if (payload.booking_to) fileName += ` sampai ${payload.booking_to}`

    payload.skip = 0
    payload.take = 20000
    payload.status = status

    let response = await bookingController.getBooking(payload)
    if (response.data.length > 0) {
      let dataBooking = _.sortBy(response.data, ['course_nama']);
      this.setState({ dataBooking, fileName })
      document.getElementById('export-to-exel-btn').click()
    }
  }

  render() {
    let { setting, dataBooking, fileName } = this.state
    return (
      <div style={{ border: "1px solid #DCDCDC", borderRadius: 5, padding: 10, marginTop: 10 }}>
        <label><b>Export Data</b></label>
        <div className="row">
          {
            setting['booking'] &&
            <div className="col-lg-4">
              <div className="form-group">
                <Download dataBooking={dataBooking} fileName={fileName} />
                <button type="button" className="btn btn-w-m btn-primary" onClick={() => this.handleExportTOExel()}>
                  <span className="fa fa-file-excel-o" /> Exel
                </button>
              </div>
            </div>
          }
          {
            setting['booking_event'] &&
            <div className="col-lg-4">
              <div className="form-group">
                <DownloadEvent dataBooking={dataBooking} fileName={fileName} />
                <button type="button" className="btn btn-w-m btn-primary" onClick={() => this.handleExportTOExel()}>
                  <span className="fa fa-file-excel-o" /> Exel
                </button>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}
