import React, { Component } from 'react';

export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minimize: false
    };
  }

  handleLogout = () => {
    localStorage.clear();
    window.location.reload()
  }

  handleMinimize = () => {
    document.getElementById('body').classList.toggle('mini-navbar')
  }

  render() {
    return (
      <div className="row border-bottom">
        <nav className="navbar navbar-static-top white-bg" role="navigation" style={{ marginBottom: 0 }}>
          <div className="navbar-header">
            <div className="minimalize-styl-2 btn btn-primary" onClick={() => this.handleMinimize()}><i className="fa fa-bars" /> </div>
          </div>
          <ul className="nav navbar-top-links navbar-right">
            <li>
              <a onClick={() => this.handleLogout()} href="/"><i className="fa fa-sign-out"></i> <span className="nav-label">Log out</span></a>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}
