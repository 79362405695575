import React, { Component } from 'react';
import Index from '../../index';
import Pagination from "react-js-pagination";
import EventController from './../../../controllers/event';
import FilterBooking from '../../../components/formFilter';

const eventController = new EventController()
const Swal = require('sweetalert2')

export default class Event extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      payload: {},
      dataEvent: [],
      hostNama: [],
      dataEventLength: 0,
      activePage: 1,
      takeData: 5,
      showDetail: false,
      dataDetail: null
    };
  }

  componentDidMount = async () => {
    await this.getDataEvent()
    this.setState({ isLoading: false })
  }

  getDataEvent = async (activePage = null, payload = {}) => {
    if (!activePage) activePage = this.state.activePage
    let take = this.state.takeData
    let skip = (activePage - 1) * take

    payload.skip = skip
    payload.take = take
    payload.all = true

    let dataEvent = []
    let hostNama = []
    let dataEventLength = 0
    let response = await eventController.getEvent(payload)
    if (response.status) {
      dataEvent = response.data
      dataEventLength = response.total_data
      
      response.host_nama.forEach(element => {
        if (element.event_host !== "") hostNama.push(element.event_host)
      });
    }
    // console.log(response)
    // console.log(hostNama)
    this.setState({ dataEvent, dataEventLength, hostNama })
  }

  handleChangeStatus = (items) => {
    let disabled = JSON.parse(JSON.stringify(items.event_deleted))
    if (disabled === "0") disabled = "1"
    else disabled = "0"

    this.setState({ showDetail: false })
    let configConfirm = {
      title: "Lanjut untuk mengubah status?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#18a689",
      confirmButtonText: "Lanjut",
    };

    Swal.fire(configConfirm).then(async (result) => {
      if (result.value) {
        items.event_deleted = disabled
        this.setState({ isLoading: true })
        await eventController.updateEvent(items)
        this.handleFilter()
      }
    });
  }

  handleDelete = (items) => {
    this.setState({ showDetail: false })
    let configConfirm = {
      title: "Lanjut untuk hapus data?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#18a689",
      confirmButtonText: "Lanjut",
    };

    Swal.fire(configConfirm).then(async (result) => {
      if (result.value) {
        this.setState({ isLoading: true })
        await eventController.deleteEvent({ event_id: items.event_id })
        this.handleFilter("delete")
      }
    });
  }

  handleFilter = async (from = null) => {
    let activePage = this.state.activePage
    let dataEventLength = this.state.dataEventLength
    let takeData = this.state.takeData
    let payload = this.state.payload
    if (from && (from === "delete")) {
      let check = dataEventLength % takeData;
      if (check === 1) activePage -= 1
    } else if (from && from === "cancel") {
      payload = {}
      this.setState({ payload })
    }
    this.setState({ isLoading: true })
    await this.getDataEvent(activePage, payload)
    this.setState({ isLoading: false })
  }

  handlePageChange = async (pageNumber) => {
    let payload = this.state.payload
    this.setState({ isLoading: true, activePage: pageNumber });
    await this.getDataEvent(pageNumber, payload)
    this.setState({ isLoading: false })
  }

  getCurrency = (price, prefix) => {
    var number_string = price.toString().replace(/[^,\d]/g, '').toString(),
      split = number_string.split(','),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi),
      separator;

    if (ribuan) {
      separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
    return prefix === undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
  };

  handlePrice = (price) => {
    if(price > 0) price = this.getCurrency(price)
    else price = 'Free'
    return price
  }

  viewItemEvent = () => {
    let dataEvent = this.state.dataEvent
    let dataEventLength = this.state.dataEventLength
    let activePage = this.state.activePage
    let takeData = this.state.takeData

    return (
      <div className="table-responsive" style={{ marginTop: 10 }}>
        <div>{`${dataEventLength} items`}</div>
        <table className="table table-hover table-bordered" style={{ fontSize: 12, marginTop: 10 }}>
          <thead>
            <tr>
              <th> No </th>
              <th> Judul </th>
              <th> Penyelenggara </th>
              <th> Harga (Rp) </th>
              <th> Status </th>
              <th> Action </th>
            </tr>
          </thead>
          <tbody>
            {
              dataEvent.map((items, keys) => (
                <tr key={keys}>
                  <td> {keys + 1 + ((activePage - 1) * takeData)} </td>
                  <td> {items.event_judul} </td>
                  <td> {items.event_host} </td>
                  <td className="text-right">
                    {this.handlePrice(items.event_harga - items.event_diskon)}
                  </td>
                  <td>
                    <div className="switch">
                      <div className="onoffswitch">
                        <input type="checkbox" checked={items.event_deleted === "0" ? true : false}
                          className="onoffswitch-checkbox" id={items.event_id}
                          onChange={() => this.handleChangeStatus(items)} />
                        <label className="onoffswitch-label" htmlFor={items.event_id}>
                          <span className="onoffswitch-inner" />
                          <span className="onoffswitch-switch" />
                        </label>
                      </div>
                    </div>
                  </td>
                  <td style={{ width: 55 }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: 55 }}>
                      {/* <button className="btn btn-xs btn-info btn-outline"
                        style={{ width: 25, height: 25 }} onClick={() => this.setState({ dataDetail: items, showDetail: true })}>
                        <i className="fa fa-info" />
                      </button> */}
                      <button className="btn btn-xs btn-warning btn-outline"
                        style={{ width: 25, height: 25 }} onClick={() => this.props.history.push(`/event/form?id=${items.event_id}`)}>
                        <i className="fa fa-pencil" />
                      </button>
                      <button className="btn btn-xs btn-danger btn-outline"
                        style={{ width: 25, height: 25 }} onClick={() => this.handleDelete(items)}>
                        <i className="fa fa-trash" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            }
            {
              dataEvent.length === 0 &&
              <tr>
                <td colSpan="7" style={{ fontWeight: "bold", textAlign: 'center' }}> Data kosong </td>
              </tr>
            }
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {
            dataEventLength !== dataEvent.length &&
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              hideDisabled
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.takeData}
              totalItemsCount={dataEventLength}
              pageRangeDisplayed={3}
              onChange={this.handlePageChange.bind(this)}
            />
          }
        </div>
      </div>
    )
  }

  pageView = () => {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox ">
            <div className="ibox-title" style={{
              display: "flex", alignItems: "center", justifyContent: "space-between"
            }}>
              <h5>Daftar Event</h5>
              <button type="button" className="btn btn-w-m btn-primary"
                onClick={() => this.props.history.push("/event/form")}
                style={{ position: "absolute", right: 20 }}>
                <i className="fa fa-plus" /> Tambah Event
              </button>
            </div>
            <div className="ibox-content">
              <FilterBooking setting={["host"]} payload={this.state.payload}
                handleFilter={(from) => this.handleFilter(from)}
                dataEvent={this.state.hostNama} />
              {this.viewItemEvent()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <Index {...this.props} pageView={this.pageView()} isLoading={this.state.isLoading} />
      </div>
    );
  }
}
