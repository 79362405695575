import React, { Component } from 'react';
import moment from 'moment';

export default class FilterBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      status: "",
      setting: {
        host: false,
        courses: false,
        noTransaction: false,
        trainer: false,
        peserta: false,
        date: false,
        event: false
      },
      payload: {},
    };
  }

  componentDidMount = async () => {
    this.handleCheckField()
  }

  handleCheckField = () => {
    let setting = this.state.setting
    this.props.setting.forEach(field => {
      setting[field] = true
      if (field === 'date') {
        let date = new Date().toJSON()
        this.handleChange("booking_to", moment(date).format('YYYY-MM-DD'))
      }
    });
    this.setState({ setting })
  }

  handleChange = (field, value) => {
    let payload = this.props.payload
    payload[field] = value
    if (value === "") delete payload[field]
    this.setState({ payload })
  }

  render() {
    let dataCourses = this.props.dataCourses
    let dataTrainer = this.props.dataTrainer
    let dataPeserta = this.props.dataPeserta
    let dataEvent = this.props.dataEvent
    let dataNamaEvent = this.props.dataNamaEvent
    let setting = this.state.setting
    let payload = this.props.payload
    let date = new Date().toJSON()

    return (
      <div style={{ border: "1px solid #DCDCDC", borderRadius: 5, padding: 10 }}>
        <div className="row">
          {
            setting['noTransaction'] &&
            <div className="col-lg-4">
              <div className="form-group">
                <label><b>Nomor Transaksi</b></label>
                <input type="number" className="form-control" placeholder="Nomor Booking"
                  value={payload.id}
                  onChange={(e) => this.handleChange("id", e.target.value)} />
              </div>
            </div>
          }
          {
            setting['event'] &&
            <div className="col-lg-4">
              <div className="form-group">
                <label><b>Event</b></label>
                <input type="text" data-provide="typeahead"
                  data-source={JSON.stringify(dataNamaEvent)}
                  value={payload.event_name}
                  placeholder="Nama Event"
                  className="form-control"
                  onChange={(e) => this.handleChange("event_name", e.target.value)}
                  onSelect={(e) => this.handleChange("event_name", e.target.value)} />
              </div>
            </div>
          }
          {
            setting['courses'] &&
            <div className="col-lg-4">
              <div className="form-group">
                <label><b>Courses</b></label>
                <input type="text" data-provide="typeahead"
                  data-source={JSON.stringify(dataCourses)}
                  value={payload.course_nama}
                  placeholder="Nama Courses"
                  className="form-control"
                  onChange={(e) => this.handleChange("course_nama", e.target.value)}
                  onSelect={(e) => this.handleChange("course_nama", e.target.value)} />
              </div>
            </div>
          }
          {
            setting['trainer'] &&
            <div className="col-lg-4">
              <div className="form-group">
                <label><b>Trainer</b></label>
                <input type="text" data-provide="typeahead"
                  data-source={JSON.stringify(dataTrainer)}
                  value={payload.trainer_nama}
                  placeholder="Nama Trainer"
                  className="form-control"
                  onChange={(e) => this.handleChange("trainer_nama", e.target.value)}
                  onSelect={(e) => this.handleChange("trainer_nama", e.target.value)} />
              </div>
            </div>
          }
          {
            setting['peserta'] &&
            <div className="col-lg-4">
              <div className="form-group">
                <label><b>Peserta</b></label>
                <input type="text" data-provide="typeahead"
                  data-source={JSON.stringify(dataPeserta)}
                  value={payload.peserta_full_nama}
                  placeholder="Nama Peserta"
                  className="form-control"
                  onChange={(e) => this.handleChange("peserta_full_nama", e.target.value)}
                  onSelect={(e) => this.handleChange("peserta_full_nama", e.target.value)} />
              </div>
            </div>
          }
          {
            setting['host'] &&
            <div className="col-lg-4">
              <div className="form-group">
                <label><b>Penyelenggara</b></label>
                <input type="text" data-provide="typeahead"
                  data-source={JSON.stringify(dataEvent)}
                  value={payload.event_host}
                  placeholder="Nama Penyelenggara"
                  className="form-control"
                  onChange={(e) => this.handleChange("event_host", e.target.value)}
                  onSelect={(e) => this.handleChange("event_host", e.target.value)} />
              </div>
            </div>
          }
          {
            setting['date'] &&
            <div className="col-lg-4">
              <div className="form-group" id="date_booking">
                <label><b>Date Create</b></label>
                <div className="input-daterange input-group" id="datepicker">
                  <input type="text"
                    style={{ height: 36 }}
                    className="form-control-sm form-control"
                    value={payload.booking_from}
                    onChange={(e) => this.handleChange("booking_from", e.target.value)}
                  />
                  <span className="input-group-addon" style={{ paddingLeft: 10, paddingRight: 10 }}>to</span>
                  <input type="text"
                    style={{ height: 36 }}
                    className="form-control-sm form-control"
                    value={payload.booking_to || moment(date).format('YYYY-MM-DD')}
                    onChange={(e) => this.handleChange("booking_to", e.target.value)}
                  />
                </div>
              </div>
            </div>
          }
          <div className="col-lg-4" style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
            <button type="button" className="btn btn-w-m btn-primary" onClick={() => this.props.handleFilter()}>
              <span className="fa fa-filter" /> Filter
            </button>
            <button type="button" className="btn btn-w-m btn-warning"
              style={{ marginLeft: 5 }} onClick={() => this.props.handleFilter('cancel')}>
              <span className="fa fa-times" /> Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}
