import React from 'react';
import Modal from "react-bootstrap/Modal";
import { LoopCircleLoading } from "react-loadingg";

function LoadingModal(props) {
  return (
    <Modal {...props} size="sm" centered>
      <Modal.Body style={{ height: 100 }}>
        <div>
          <LoopCircleLoading />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default LoadingModal
