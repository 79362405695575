import { Component } from 'react';
import fetchApi from '../fetchApi';
const encryptor = require('simple-encryptor')(process.env.REACT_APP_API_URL);

const Swal = require('sweetalert2')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

export default class AuthController extends Component {
  login = async (payload) => {
    try {
      let path = `auth?username=${payload.username}&password=${payload.password}`
      let response = await fetchApi("GET", path);
      if (response.status) {
        response.isLogin = true
        response.username = payload.username
        localStorage.setItem('account_login', JSON.stringify(true));
        localStorage.setItem('account', JSON.stringify(encryptor.encrypt(response)));
        window.location.reload()
      } else {
        Toast.fire({ icon: 'error', title: response.message })
      }
      return response
    } catch (error) {
      console.log(error)
      return false
    }
  }
}
