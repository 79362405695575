import firebase from 'firebase/app';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCLoW5KRA0TN7SJd2mXCc1lJoSsXVDM438",
  authDomain: "pengen-bisa.firebaseapp.com",
  databaseURL: "https://pengen-bisa.firebaseio.com",
  projectId: "pengen-bisa",
  storageBucket: "pengen-bisa.appspot.com",
  messagingSenderId: "860355409741",
  appId: "1:860355409741:web:a04ffb08a632ce7ab4e904",
  measurementId: "G-G3T81Q4ZMM"
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };
