import React, { Component } from 'react';
import { Redirect, Route, Switch, HashRouter } from 'react-router-dom';
import { LoopCircleLoading } from 'react-loadingg';

// Page Login
import Login from './views/login/login';
// Page Dashboard
import Dashboard from './views/admin/dashboard/dashboard';
// Page Courses
import Trainer from './views/admin/trainer/trainer';
import FormTrainer from './views/admin/trainer/formTrainer';
// Page Courses
import Courses from './views/admin/courses/courses';
import FormCourses from './views/admin/courses/formCourses';
// Page Courses
import Mitra from './views/admin/mitra/mitra';
// Page Booking Kelas
import KelasMenunggu from './views/admin/booking/bookingkelas/menunggu';
import KelasLunas from './views/admin/booking/bookingkelas/lunas';
// Page Booking Event
import EventMenunggu from './views/admin/booking/bookingevent/menunggu';
import EventLunas from './views/admin/booking/bookingevent/lunas';
// Page Banner
import Promotion from './views/admin/promotion/promotion';
import FormPromotion from './views/admin/promotion/formPromotion';
// Page Peserta
import Peserta from './views/admin/peserta/peserta';
// Page Event
import Event from './views/admin/event/event';
import FormEvent from './views/admin/event/formEvent';
// Page Broadcast
import Broadcast from './views/admin/broadcast/broadcast';

const encryptor = require('simple-encryptor')(process.env.REACT_APP_API_URL);
const loading = () => <div className="animated fadeIn pt-3 text-center"><LoopCircleLoading /></div>;

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: JSON.parse(localStorage.getItem('account_login')) || false,
    };
  }

  componentDidMount = async () => {
    let account = encryptor.decrypt(JSON.parse(localStorage.getItem('account')));
    if (account) {
      if (account.isLogin) this.setState({ isLogin: true })
    } else {
      this.setState({ isLogin: false })
      localStorage.clear();
    }
  }

  render() {
    if (!this.state.isLogin) {
      return (
        <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login" render={props => <Login {...props} />} />
              <Redirect to="/login" />
            </Switch>
          </React.Suspense>
        </HashRouter>
      );
    } else {
      return (
        <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/" name="Dashboard" render={props => <Dashboard {...props} />} />
              <Route exact path="/courses" name="Courses" render={props => <Courses {...props} />} />
              <Route exact path="/courses/form" name="Courses" render={props => <FormCourses {...props} />} />
              <Route exact path="/bookingkelas" name="Booking Kelas" render={props => <KelasMenunggu {...props} />} />
              <Route exact path="/bookingkelas/menunggu" name="Booking Kelas" render={props => <KelasMenunggu {...props} />} />
              <Route exact path="/bookingkelas/lunas" name="Booking Kelas" render={props => <KelasLunas {...props} />} />
              <Route exact path="/trainer" name="Trainer" render={props => <Trainer {...props} />} />
              <Route exact path="/trainer/form" name="Trainer" render={props => <FormTrainer {...props} />} />
              <Route exact path="/mitra" name="Mitra" render={props => <Mitra {...props} />} />
              <Route exact path="/peserta" name="Peserta" render={props => <Peserta {...props} />} />
              <Route exact path="/promotion" name="Promotion" render={props => <Promotion {...props} />} />
              <Route exact path="/promotion/form" name="Promotion" render={props => <FormPromotion {...props} />} />
              <Route exact path="/event" name="Event" render={props => <Event {...props} />} />
              <Route exact path="/event/form" name="Event" render={props => <FormEvent {...props} />} />
              <Route exact path="/bookingevent" name="Booking Event" render={props => <EventMenunggu {...props} />} />
              <Route exact path="/bookingevent/menunggu" name="Booking Event" render={props => <EventMenunggu {...props} />} />
              <Route exact path="/bookingevent/lunas" name="Booking Event" render={props => <EventLunas {...props} />} />
              <Route exact path="/broadcast" name="Broadcast" render={props => <Broadcast {...props} />} />
              <Redirect to="/" />
            </Switch>
          </React.Suspense>
        </HashRouter>
      );
    }
  }
}
