import React, { Component } from 'react';
import Index from '../../index';
import PromotionController from './../../../controllers/promotion';

const promotionController = new PromotionController()
const Swal = require('sweetalert2')

export default class Promotion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      dataPromotion: [],
      dataPromotionLength: 0,
      activePage: 1,
      takeData: 5,
    };
  }

  componentDidMount = async () => {
    await this.getDataPromotion()
    this.setState({ isLoading: false })
  }

  getDataPromotion = async (activePage = null, payload = {}) => {
    if (!activePage) activePage = this.state.activePage
    let take = this.state.takeData
    let skip = (activePage - 1) * take

    payload.skip = skip
    payload.take = take
    payload.all = true

    let dataPromotion = []
    let dataPromotionLength = 0
    let response = await promotionController.getPromotion(payload)
    if (response.status) {
      dataPromotion = response.data
      dataPromotionLength = response.total_data
    }
    // console.log(response)
    this.setState({ dataPromotion, dataPromotionLength })
  }

  handleDelete = (items) => {
    this.setState({ showDetail: false })
    let configConfirm = {
      title: "Lanjut untuk hapus data?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#18a689",
      confirmButtonText: "Lanjut",
    };

    Swal.fire(configConfirm).then(async (result) => {
      if (result.value) {
        this.setState({ isLoading: true })
        await promotionController.deletePromotion({ id: items.promotion_id })
        this.handleFilter("delete")
      }
    });
  }

  handleFilter = async (from = null) => {
    let activePage = this.state.activePage
    let dataPromotionLength = this.state.dataPromotionLength
    let takeData = this.state.takeData
    let payload = this.state.payload
    if (from && (from === "delete")) {
      let check = dataPromotionLength % takeData;
      if (check === 1) activePage -= 1
    }
    this.setState({ isLoading: true })
    await this.getDataPromotion(activePage, payload)
    this.setState({ isLoading: false })
  }

  viewItemBanner = () => {
    let { dataPromotion, dataPromotionLength, activePage, takeData } = this.state
    return (
      <div className="table-responsive" style={{ marginTop: 10 }}>
        <table className="table table-hover table-bordered" style={{ fontSize: 12, marginTop: 10 }}>
          <thead>
            <tr>
              <th style={{ width: 15 }}> No </th>
              <th> TItle </th>
              <th> Deskripsi </th>
              <th> Action </th>
            </tr>
          </thead>
          <tbody>
            {
              dataPromotion.map((items, keys) => (
                <tr key={keys}>
                  <td> {keys + 1 + ((activePage - 1) * takeData)} </td>
                  <td> {items.promotion_title} </td>
                  <td> {items.promotion_desc} </td>
                  <td style={{ width: 55 }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: 55 }}>
                      <button className="btn btn-xs btn-warning btn-outline"
                        style={{ width: 25, height: 25 }} onClick={() => this.props.history.push(`/promotion/form?id=${items.promotion_id}`)}>
                        <i className="fa fa-pencil" />
                      </button>
                      <button className="btn btn-xs btn-danger btn-outline"
                        style={{ width: 25, height: 25 }} onClick={() => this.handleDelete(items)}>
                        <i className="fa fa-trash" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            }
            {
              dataPromotionLength === 0 &&
              <tr>
                <td colSpan="4" style={{ fontWeight: "bold", textAlign: 'center' }}> Data kosong </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    )
  }

  pageView = () => {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox ">
            <div className="ibox-title" style={{
              display: "flex", alignItems: "center", justifyContent: "space-between"
            }}>
              <h5>Daftar Promotion</h5>
              <button type="button" className="btn btn-w-m btn-primary"
                onClick={() => this.props.history.push("/promotion/form")}
                style={{ position: "absolute", right: 20 }}>
                <i className="fa fa-plus" /> Tambah Promotion
              </button>
            </div>
            <div className="ibox-content">
              {this.viewItemBanner()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <Index {...this.props} pageView={this.pageView()} isLoading={this.state.isLoading} />
    );
  }
}
