import React, { Component } from 'react';
import Index from '../../index';
import Pagination from "react-js-pagination";
import DetailTrainer from '../../../components/modal/detailTrainer';
import TrainerController from './../../../controllers/trainer';
import FilterBooking from '../../../components/formFilter';

const trainerController = new TrainerController()
const Swal = require('sweetalert2')

export default class Trainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      payload: {},
      dataTrainer: [],
      trainerNama: [],
      dataTrainerLength: 0,
      activePage: 1,
      takeData: 5,
      showDetail: false,
      dataDetail: null
    };
  }

  componentDidMount = async () => {
    await this.getDataTrainer()
    this.setState({ isLoading: false })
  }

  getDataTrainer = async (activePage = null, payload = {}) => {
    if (!activePage) activePage = this.state.activePage
    let take = this.state.takeData
    let skip = (activePage - 1) * take

    payload.skip = skip
    payload.take = take

    let dataTrainer = []
    let trainerNama = []
    let dataTrainerLength = 0
    let response = await trainerController.getTrainer(payload)
    if (response.status) {
      dataTrainer = response.data
      dataTrainerLength = response.total_data
    }
    response.data_nama.forEach(element => {
      trainerNama.push(element.trainer_nama)
    });
    // console.log(dataTrainer)
    this.setState({ dataTrainer, dataTrainerLength, trainerNama })
  }

  handleChangeStatus = (items) => {
    let disabled = JSON.parse(JSON.stringify(items.trainer_deleted))
    if (disabled === "0") disabled = "1"
    else disabled = "0"

    this.setState({ showDetail: false })
    let configConfirm = {
      title: "Lanjut untuk mengubah status?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#18a689",
      confirmButtonText: "Lanjut",
    };

    Swal.fire(configConfirm).then(async (result) => {
      if (result.value) {
        this.setState({ isLoading: true })
        await trainerController.deleteTrainer({ id: items.trainer_id, disabled })
        this.handleFilter()
      }
    });
  }

  handleDelete = (items) => {
    this.setState({ showDetail: false })
    let configConfirm = {
      title: "Lanjut untuk hapus data?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#18a689",
      confirmButtonText: "Lanjut",
    };

    Swal.fire(configConfirm).then(async (result) => {
      if (result.value) {
        this.setState({ isLoading: true })
        await trainerController.deleteTrainer({ id: items.trainer_id, disabled: 2 })
        this.handleFilter("delete")
      }
    });
  }

  handleFilter = async (from = null) => {
    let activePage = this.state.activePage
    let dataTrainerLength = this.state.dataTrainerLength
    let takeData = this.state.takeData
    let payload = this.state.payload
    if (from && (from === "delete")) {
      let check = dataTrainerLength % takeData;
      if (check === 1) activePage -= 1
    } else if (from && from === "cancel") {
      payload = {}
      this.setState({ payload })
    }
    this.setState({ isLoading: true })
    await this.getDataTrainer(activePage, payload)
    this.setState({ isLoading: false })
  }

  handlePageChange = async (pageNumber) => {
    let payload = this.state.payload
    this.setState({ isLoading: true, activePage: pageNumber });
    await this.getDataTrainer(pageNumber, payload)
    this.setState({ isLoading: false })
  }

  viewItemTrainer = () => {
    let dataTrainer = this.state.dataTrainer
    let dataTrainerLength = this.state.dataTrainerLength
    let activePage = this.state.activePage
    let takeData = this.state.takeData
    return (
      <div className="table-responsive" style={{ marginTop: 10 }}>
        <div>{`${dataTrainerLength} items`}</div>
        <table className="table table-hover table-bordered" style={{ fontSize: 12, marginTop: 10 }}>
          <thead>
            <tr>
              <th> No </th>
              <th> Nomor Hp </th>
              <th> Nama </th>
              <th> Email </th>
              <th> Status </th>
              <th> Action </th>
            </tr>
          </thead>
          <tbody>
            {
              dataTrainer.map((items, keys) => (
                <tr key={keys}>
                  <td> {keys + 1 + ((activePage - 1) * takeData)} </td>
                  <td> {items.trainer_nohp} </td>
                  <td> {items.trainer_nama} </td>
                  <td> {items.trainer_username} </td>
                  <td>
                    <div className="switch">
                      <div className="onoffswitch">
                        <input type="checkbox" checked={items.trainer_deleted === "0" ? true : false}
                          className="onoffswitch-checkbox" id={items.trainer_id}
                          onChange={() => this.handleChangeStatus(items)} />
                        <label className="onoffswitch-label" htmlFor={items.trainer_id}>
                          <span className="onoffswitch-inner" />
                          <span className="onoffswitch-switch" />
                        </label>
                      </div>
                    </div>
                  </td>
                  <td style={{ width: 80 }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: 80 }}>
                      <button className="btn btn-xs btn-info btn-outline"
                        style={{ width: 25, height: 25 }} onClick={() => this.setState({ dataDetail: items, showDetail: true })}>
                        <i className="fa fa-info" />
                      </button>
                      <button className="btn btn-xs btn-warning btn-outline"
                        style={{ width: 25, height: 25 }} onClick={() => this.props.history.push(`/trainer/form?id=${items.trainer_id}`)}>
                        <i className="fa fa-pencil" />
                      </button>
                      <button className="btn btn-xs btn-danger btn-outline"
                        style={{ width: 25, height: 25 }} onClick={() => this.handleDelete(items)}>
                        <i className="fa fa-trash" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            }
            {
              dataTrainer.length === 0 &&
              <tr>
                <td colSpan="7" style={{ fontWeight: "bold", textAlign: 'center' }}> Data kosong </td>
              </tr>
            }
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {
            dataTrainerLength !== dataTrainer.length &&
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              hideDisabled
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.takeData}
              totalItemsCount={dataTrainerLength}
              pageRangeDisplayed={3}
              onChange={this.handlePageChange.bind(this)}
            />
          }
        </div>
      </div>
    )
  }

  pageView = () => {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox ">
            <div className="ibox-title" style={{
              display: "flex", alignItems: "center", justifyContent: "space-between"
            }}>
              <h5>Daftar Trainer</h5>
              <button type="button" className="btn btn-w-m btn-primary"
                onClick={() => this.props.history.push("/trainer/form")}
                style={{ position: "absolute", right: 20 }}>
                <i className="fa fa-plus" /> Tambah Trainer
              </button>
            </div>
            <div className="ibox-content">
              <FilterBooking setting={["trainer"]} payload={this.state.payload}
                handleFilter={(from) => this.handleFilter(from)}
                dataTrainer={this.state.trainerNama} />
              {this.viewItemTrainer()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <DetailTrainer
          show={this.state.showDetail}
          onHide={() => this.setState({ showDetail: false })}
          handleEdit={(items) => this.props.history.push(`/trainer/form?id=${items.trainer_id}`)}
          data={this.state.dataDetail} />
        <Index {...this.props} pageView={this.pageView()} isLoading={this.state.isLoading} />
      </div>
    );
  }
}
