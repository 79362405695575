import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Logo512x125 from './../../assets/img/logo-512x125.png';
import Logo512x512 from './../../assets/img/logo-512x512.png';
import routes from '../../config/routes';

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      route: routes,
    };
  }

  activeRoute(route) {
    let active = false
    let check = 0
    let url = window.location.hash.split("#")[1]

    for (const key in route.path.split("/")) {
      if (url.split("/")[key] === route.path.split("/")[key]) check += 1
    }

    if (check === route.path.split("/").length) active = true

    if (active) {
      document.title = `${route.name} | ${process.env.REACT_APP_NAME}`
      if (!route.child) route.expand = true
    } else {
      route.expand = false
    }
    return active && route.expand ? "active" : "";
  }

  handleExpand = (items) => {
    items.expand = items.expand ? false : true
  }

  render() {
    let route = this.state.route
    return (
      <nav className="navbar-default navbar-static-side" role="navigation">
        <div className="sidebar-collapse">
          <ul className="nav metismenu" id="side-menu">
            <li className="nav-header">
              <div className="dropdown profile-element">
                <img src={Logo512x125} alt="pengen bisa logo" style={{ height: 45, width: 160, marginTop: -10 }} />
              </div>
              <div className="logo-element">
                <img src={Logo512x512} alt="pengen bisa logo" style={{ height: 50, width: 50, marginTop: -20, marginBottom: -20 }} />
              </div>
            </li>
            {
              route.map((items, keys) => (
                <li key={keys} className={this.activeRoute(items)}>
                  <Link to={items.path} onClick={() => items.child && this.handleExpand(items)}>
                    <i className={items.icon}></i>
                    <span className="nav-label">{items.name}</span>
                    {items.child && <span className="fa arrow" />}
                  </Link>
                  {
                    items.child && items.expand &&
                    <ul className="nav nav-second-level">
                      {
                        items.child.map((item, key) => (
                          <li key={key} className={this.activeRoute(item)}>
                            <Link to={item.path}> {item.name}</Link>
                          </li>
                        ))
                      }
                    </ul>
                  }
                </li>
              ))
            }
          </ul>
        </div>
      </nav>
    );
  }
}
