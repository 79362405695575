import React from 'react';
import Modal from "react-bootstrap/Modal";

function DetailCourses(props) {
  let data = props.data
  return (
    <Modal {...props} centered>
      {
        data &&
        <Modal.Body>
          ini
        </Modal.Body>
      }
      <Modal.Footer>
        <button type="button" className="btn btn-w-m btn-danger" onClick={() => props.onHide()}>
          <i className="fa fa-sign-out" /> Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default DetailCourses
