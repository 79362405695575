const routes = [
  {
    path: "/",
    name: "Dashboard",
    icon: "fa fa-th-large",
  },
  {
    path: "/bookingkelas",
    name: "Booking Kelas",
    icon: "fa fa-book",
    expand: true,
    child: [
      {
        path: "/bookingkelas/menunggu",
        name: "Menunggu",
      },
      {
        path: "/bookingkelas/lunas",
        name: "Lunas",
      },
    ]
  },
  {
    path: "/bookingevent",
    name: "Booking Event",
    icon: "fa fa-book",
    expand: true,
    child: [
      {
        path: "/bookingevent/menunggu",
        name: "Menunggu",
      },
      {
        path: "/bookingevent/lunas",
        name: "Lunas",
      },
    ]
  },
  {
    path: "/trainer",
    name: "Trainer",
    icon: "fa fa-address-book-o",
  },
  {
    path: "/courses",
    name: "Courses",
    icon: "fa fa-group",
  },
  {
    path: "/event",
    name: "Event",
    icon: "fa fa-calendar-check-o",
  },
  {
    path: "/peserta",
    name: "Peserta",
    icon: "fa fa-address-book",
  },
  {
    path: "/promotion",
    name: "Promotion",
    icon: "fa fa-picture-o",
  },
  {
    path: "/broadcast",
    name: "Broadcast",
    icon: "fa fa-bullhorn",
  },
];

export default routes;
