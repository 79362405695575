import React, { Component } from 'react';
import Index from '../../index';
import PromotionController from '../../../controllers/promotion';
import { storage } from '../../../config/firebase';

const promotionController = new PromotionController()

export default class FormPromotion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      progress: 0,
      isEdit: false,
      dataPromotion: {
        promotion_title: "",
        promotion_desc: "",
        promotion_image: "https://via.placeholder.com/600x200.png",
      },
    };
  }

  componentDidMount = async () => {
    const id = new URLSearchParams(this.props.location.search).get("id");
    this.getDataPromotion(id)
  }

  getDataPromotion = async (id) => {
    let payload = { take: 1, skip: 0 }
    if (id) {
      payload.id = id
      let dataPromotion = await promotionController.getPromotion(payload)
      // console.log(dataPromotion)
      if (dataPromotion.status) {
        dataPromotion = dataPromotion.data[0]
        this.setState({ dataPromotion, isEdit: true })
      }
    }
    this.setState({ isLoading: false })
  }

  handleSave = async () => {
    this.setState({ isLoading: true })
    let dataPromotion = this.state.dataPromotion
    let isEdit = this.state.isEdit

    // console.log(dataPromotion)
    let response = {}
    if (isEdit) response = await promotionController.updatePromotion(dataPromotion)
    else response = await promotionController.addPromotion(dataPromotion)

    if (response.status) this.props.history.goBack()
    this.setState({ isLoading: false })
  }

  convertToSlug = (nameBanner, nameFaile) => {
    if (nameBanner === "") nameBanner = nameFaile
    return nameBanner.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-');
  }

  handleChange = (field, value) => {
    let dataPromotion = this.state.dataPromotion
    if (field === "promotion_image") {
      let url_name = this.convertToSlug(dataPromotion.promotion_title, value.name)
      const uploadTask = storage.ref(`banner/${url_name}`).put(value)
      uploadTask.on("state_changed",
        snapshot => {
          const progress = Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100
          this.setState({ progress })
        }, error => {
          console.log(error)
        }, () => {
          storage.ref("banner").child(url_name)
            .getDownloadURL().then(url => {
              dataPromotion[field] = url
              this.setState({ dataPromotion })
            })
        })
    } else {
      dataPromotion[field] = value
      this.setState({ dataPromotion })
    }
  }

  viewItemPromotion = () => {
    let progress = this.state.progress
    let dataPromotion = this.state.dataPromotion
    return (
      <div>
        <div style={{ border: "1px solid #DCDCDC", borderRadius: 5, padding: 10 }}>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label><b>TItle</b></label>
                <input type="text" className="form-control" value={dataPromotion.promotion_title}
                  onChange={(e) => this.handleChange("promotion_title", e.target.value)} />
              </div>
              <div className="form-group">
                <label><b>Deskripsi Promotion</b></label>
                <textarea className="form-control" value={dataPromotion.promotion_desc} rows={3}
                  onChange={(e) => this.handleChange("promotion_desc", e.target.value)} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label><b>Banner</b></label>
                <div style={{ marginBottom: 5, marginTop: -5 }}>
                  <img alt="promotion" className="m-t-xs img-responsive" id="gambar_preview"
                    src={dataPromotion.promotion_image}
                    style={{ height: 100, width: '100%', borderRadius: 2, objectFit: "cover", overflow: 'hidden' }} />
                </div>

                <div className="progress progress-mini" style={{ marginBottom: 5 }}>
                  <div className="progress-bar progress-bar-primary" style={{ width: `${progress}%` }}
                    role="progressbar" aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100} />
                </div>

                <div className="custom-file">
                  <input id="foto" type="file" className="custom-file-input"
                    onChange={(e) => this.handleChange("promotion_image", e.target.files[0])} />
                  <label htmlFor="foto" className="custom-file-label">Choose file...</label>
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
            <button type="button" className="btn btn-w-m btn-warning"
              onClick={() => this.props.history.goBack()}>
              <i className="fa fa-arrow-left" /> Kembali
            </button>
            <button type="button" className="btn btn-w-m btn-primary"
              style={{ marginLeft: 10 }} onClick={() => this.handleSave()}>
              <i className="fa fa-save" /> Save
            </button>
          </div>
        </div>
      </div>
    )
  }

  pageView = () => {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox ">
            <div className="ibox-title">
              <h5>Form Promotion</h5>
            </div>
            <div className="ibox-content">
              {this.viewItemPromotion()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <Index {...this.props} pageView={this.pageView()} isLoading={this.state.isLoading} />
    );
  }
}
