import { Component } from 'react';
import fetchApi from '../fetchApi';

export default class SendMail extends Component {
  sendSMTP = async (payload) => {
    try {
      let response = await fetchApi("POST", `SendCustomEmail`, payload);
      return response
    } catch (error) {
      console.log(error)
      return false
    }
  }
}
