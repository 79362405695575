import React from 'react';
import Modal from "react-bootstrap/Modal";
import moment from 'moment';

function getCurrency(price, prefix) {
  var number_string = price.toString().replace(/[^,\d]/g, '').toString(),
    split = number_string.split(','),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi),
    separator;

  if (ribuan) {
    separator = sisa ? '.' : '';
    rupiah += separator + ribuan.join('.');
  }

  rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
  return prefix === undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
};

function DetailBooking(props) {
  let data = props.data
  let peserta_image, event_image
  if (data) {
    if (data.peserta_image.includes('assets/foto')) {
      peserta_image = `https://www.pengenbisa.com/${data.peserta_image}`
    } else {
      peserta_image = data.peserta_image
    }
    if (data.event_image.includes('assets/gambar')) {
      event_image = `https://www.pengenbisa.com/${data.event_image}`
    } else {
      event_image = data.event_image
    }
  }

  return (
    <Modal {...props} centered>
      {
        data &&
        <Modal.Body>
          <div style={{ margin: -10, marginTop: 10 }}>
            <div style={{}}>
              <div style={{ fontSize: 14, fontWeight: "bold" }}>Informasi Booking</div>
              <div style={{
                border: "1px solid #CDCDCD", borderRadius: 5, padding: 10,
                paddingTop: 5, paddingBottom: 5, marginTop: 5, display: "flex",
                alignItems: "center"
              }}>
                <div>
                  <img src={peserta_image} alt="peserta pengen bisa"
                    style={{ height: 60, width: 60, borderRadius: 5, objectFit: "cover", overflow: 'hidden' }} />
                </div>
                <div style={{ marginLeft: 10 }}>
                  <div style={{ fontWeight: "bold" }}> {data.peserta_full_nama} </div>
                  <div style={{ fontSize: 11 }}> {data.peserta_nohp} </div>
                  <div style={{ fontSize: 11 }}> {data.booking_peserta} </div>
                </div>
              </div>
            </div>
            <div style={{ backgroundColor: "#CDCDCD", height: 1, marginTop: 10, marginBottom: 10 }} />
            <div style={{ marginLeft: 5, marginRight: 5, display: "flex", justifyContent: "space-between" }}>
              <div style={{ fontSize: 12 }}>STATUS</div>
              <div style={{ fontSize: 14, fontWeight: "bold", color: data.booking_status === "Terbayar" ? "#1BB394" : "#F8AC5A" }}>
                {data.booking_status}
              </div>
            </div>
            <div style={{ backgroundColor: "#CDCDCD", height: 1, marginTop: 10, marginBottom: 10 }} />
            <div style={{ marginLeft: 5, marginRight: 5, display: "flex", justifyContent: "space-between" }}>
              <div style={{ fontSize: 12 }}>NOMOR TRANSAKSI</div>
              <div style={{ fontSize: 14, fontWeight: "bold" }}>{data.booking_id}</div>
            </div>
            <div style={{ backgroundColor: "#CDCDCD", height: 1, marginTop: 10, marginBottom: 10 }} />
            <div style={{ marginLeft: 5, marginRight: 5, display: "flex", justifyContent: "space-between" }}>
              <div style={{ fontSize: 12 }}>TANGGAL BOOKING</div>
              <div style={{ fontSize: 14, fontWeight: "bold" }}>{moment(data.booking_create).format("LL")}</div>
            </div>
            <div style={{ backgroundColor: "#CDCDCD", height: 1, marginTop: 10, marginBottom: 10 }} />
            <div style={{ marginLeft: 5, marginRight: 5, }}>
              <div style={{ fontSize: 12 }}>METODE PEMBAYARAN</div>
              <div style={{
                border: "1px solid #CDCDCD", borderRadius: 5, padding: 10,
                paddingTop: 5, paddingBottom: 5, marginTop: 5
              }}>
                {
                  data.booking_jenis_bank === "Transfer BANK" &&
                  <div>
                    <div style={{ fontWeight: "bold" }}>{data.booking_jenis_bank.toUpperCase()}</div>
                    <div style={{ fontSize: 11 }}>BANK BRI (0052-01-109571-509)</div>
                    <div style={{ fontSize: 11 }}>A.N Denda Juita Suastri</div>
                  </div>
                }
                {
                  data.booking_jenis_bank === "Pembayaran Secara Langsung" &&
                  <div>
                    <div style={{ fontWeight: "bold" }}>{data.booking_jenis_bank.toUpperCase()}</div>
                    <div style={{ fontSize: 11 }}>Jln. Swasembada 1A, No. 13, Kekalik Jaya, Sekarbela,</div>
                    <div style={{ fontSize: 11 }}>Kota Mataram, Nusa Tenggara Barat.</div>
                  </div>
                }
              </div>
            </div>
            <div style={{ backgroundColor: "#CDCDCD", height: 1, marginTop: 10, marginBottom: 10 }} />
            <div style={{ marginLeft: 5, marginRight: 5, }}>
              <div style={{ fontSize: 12 }}>EVENT</div>
              <div style={{
                border: "1px solid #CDCDCD", borderRadius: 5, padding: 10,
                paddingTop: 5, paddingBottom: 5, marginTop: 5, display: "flex",
                alignItems: "center"
              }}>
                <div>
                  <img src={event_image} alt="courses pengen bisa"
                    style={{ height: 60, width: 60, borderRadius: 5, objectFit: "cover", overflow: 'hidden' }} />
                </div>
                <div style={{ marginLeft: 10 }}>
                  <div style={{ fontWeight: "bold" }}> {data.event_judul} </div>
                  <div style={{ fontSize: 11 }}>
                    {(data.event_peserta ? (data.event_peserta + "/" + data.event_kuota) : data.event_kuota) + " peserta"}
                  </div>
                  <div style={{ fontSize: 11 }}> {getCurrency(data.event_harga - data.event_diskon, "Rp.")} </div>
                  <div style={{ fontSize: 11 }}> {data.event_host} </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: 30, marginLeft: -15, marginRight: -15 }}>
            <button type="button" className={`btn btn-w-m ${data.booking_status === "Terbayar" ? "btn-warning" : "btn-primary"}`}
              style={{ width: "45%" }} onClick={() => props.handleVerification(data)}>
              <i className={data.booking_status === "Terbayar" ? "fa fa-times" : "fa fa-check"} /> {data.booking_status === "Terbayar" ? "Cancel" : "Verifikasi"}
            </button>

            <button type="button" className="btn btn-w-m btn-danger"
              style={{ width: "45%" }} onClick={() => props.handleDelete(data)}>
              <i className="fa fa-trash" /> Hapus
            </button>
          </div>
        </Modal.Body>
      }
      <Modal.Footer>
        <button type="button" className="btn btn-w-m btn-danger" onClick={() => props.onHide()}>
          <i className="fa fa-sign-out" /> Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default DetailBooking
