import React, { Component } from 'react';
import Index from '../../index';

export default class Mitra extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }

  componentDidMount = async () => {
    this.setState({ isLoading: false })
  }

  pageView = () => {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center m-t-lg">
            <h1>
              Mitra
            </h1>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <Index {...this.props} pageView={this.pageView()} isLoading={this.state.isLoading} />
    );
  }
}
