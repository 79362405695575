import { Component } from 'react';
import fetchApi from '../fetchApi';

export default class ReportController extends Component {
  getReport = async (payload = { reportName: 'category' }) => {
    try {
      let response = await fetchApi("POST", `report`, payload);
      // console.log(response)
      return response
    } catch (error) {
      console.log(error)
      return false
    }
  }
}
