import React, { Component } from 'react';
import Index from '../../index';
import { storage } from '../../../config/firebase';
import TrainerController from './../../../controllers/trainer';

const trainerController = new TrainerController()

export default class FormTrainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      progress: 0,
      isEdit: false,
      dataTrainer: {
        trainer_nama: "",
        trainer_username: "",
        trainer_password: "",
        trainer_nohp: "",
        trainer_tgl: "",
        trainer_tpl: "",
        trainer_desc: "",
        trainer_alamat: "",
        trainer_motivasi: "",
        trainer_available: "",
        trainer_image: "assets/foto/400x600.png",
        trainer_jk: ""
      }
    };
  }

  componentDidMount = async () => {
    const id = new URLSearchParams(this.props.location.search).get("id");
    if (id) {
      let dataTrainer = await trainerController.getTrainer({ id })
      if (dataTrainer.status) {
        dataTrainer = dataTrainer.data[0]
        dataTrainer.trainer_password = ""
        console.log(dataTrainer.trainer_available)
        this.setState({ dataTrainer, isEdit: true })
      }
    }
    this.setState({ isLoading: false })
  }

  handleSave = async () => {
    this.setState({ isLoading: true })
    let dataTrainer = this.state.dataTrainer
    let isEdit = this.state.isEdit

    let response = {}
    if (isEdit) response = await trainerController.updateTrainer(dataTrainer)
    else response = await trainerController.addTrainer(dataTrainer)

    if (response.status) this.props.history.goBack()
    this.setState({ isLoading: false })
  }

  convertToSlug = (nameTrainer, nameFaile) => {
    if (nameTrainer === "") nameTrainer = nameFaile
    return nameTrainer.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-');
  }

  handleChange = (field, value) => {
    let dataTrainer = this.state.dataTrainer
    if (field === "trainer_image") {
      let url_name = this.convertToSlug(dataTrainer.trainer_nama, value.name)
      const uploadTask = storage.ref(`images/${url_name}`).put(value)
      uploadTask.on("state_changed",
        snapshot => {
          const progress = Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100
          this.setState({ progress })
        }, error => {
          console.log(error)
        }, () => {
          storage.ref("images").child(url_name)
            .getDownloadURL().then(url => {
              dataTrainer[field] = url
              this.setState({ dataTrainer })
            })
        })
    } else {
      dataTrainer[field] = value
      this.setState({ dataTrainer })
    }
  }

  viewItemTrainer = () => {
    let progress = this.state.progress
    let dataTrainer = this.state.dataTrainer
    let trainer_image = dataTrainer.trainer_image
    if (dataTrainer.trainer_image.includes('assets/foto')) {
      trainer_image = `https://www.pengenbisa.com/${dataTrainer.trainer_image}`
    }

    return (
      <div>
        <div style={{ marginBottom: 10 }}>INFO AKUN</div>
        <div style={{ border: "1px solid #DCDCDC", borderRadius: 5, padding: 10 }}>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label><b>Nama</b></label>
                <input type="text" className="form-control" value={dataTrainer.trainer_nama}
                  onChange={(e) => this.handleChange("trainer_nama", e.target.value)} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label><b>Nomor Hp</b></label>
                <input type="text" className="form-control" value={dataTrainer.trainer_nohp}
                  onChange={(e) => this.handleChange("trainer_nohp", e.target.value)} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label><b>Email</b></label>
                <input type="text" className="form-control" value={dataTrainer.trainer_username}
                  onChange={(e) => this.handleChange("trainer_username", e.target.value)} />
                <small>Email ini akan digunakan sebagai <b>username</b></small>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label><b>Password</b></label>
                <input type="password" className="form-control" value={dataTrainer.trainer_password}
                  onChange={(e) => this.handleChange("trainer_password", e.target.value)} />
                <small>Isi form password bila akan mengubah <b>password</b></small>
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: 10, marginTop: 20 }}>INFO TAMBAHAN</div>
        <div style={{ border: "1px solid #DCDCDC", borderRadius: 5, padding: 10 }}>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group" id="tanggal_lahir_trainer">
                <label><b>Tanggal Lahir</b></label>
                <div className="input-group date">
                  <span className="input-group-addon"><i className="fa fa-calendar" /></span>
                  <input type="text" className="form-control" value={dataTrainer.trainer_tgl}
                    onChange={(e) => this.handleChange("trainer_tgl", e.target.value)} />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label><b>Tempat Lahir</b></label>
                <input type="text" className="form-control" value={dataTrainer.trainer_tpl}
                  onChange={(e) => this.handleChange("trainer_tpl", e.target.value)} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label><b>Alamat</b></label>
                <textarea className="form-control" value={dataTrainer.trainer_alamat}
                  onChange={(e) => this.handleChange("trainer_alamat", e.target.value)} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label><b>Deskripsi</b></label>
                <textarea className="form-control" value={dataTrainer.trainer_desc}
                  onChange={(e) => this.handleChange("trainer_desc", e.target.value)} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label><b>Motivasi</b></label>
                <textarea className="form-control" value={dataTrainer.trainer_motivasi}
                  onChange={(e) => this.handleChange("trainer_motivasi", e.target.value)} />
              </div>

              <div className="form-group">
                <label><b>Available</b></label>
                <input type="text" className="form-control" value={dataTrainer.trainer_available}
                  onChange={(e) => this.handleChange("trainer_available", e.target.value)} />
              </div>

              <div className="form-group">
                <label><b>Jenis Kelamin</b></label>
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                    onClick={() => this.handleChange('trainer_jk', "Laki-Laki")}>
                    <div style={{
                      backgroundColor: (dataTrainer.trainer_jk === "Laki-Laki" ? "#1ab394" : "gray"), height: 20, width: 20, borderRadius: 20,
                      display: "flex", alignItems: "center", justifyContent: "center"
                    }}>
                      <i className={dataTrainer.trainer_jk === "Laki-Laki" ? "fa fa-check-circle" : "fa fa-circle"} style={{ fontSize: 15, color: "#FFF" }}></i>
                    </div>
                    <div style={{ marginLeft: 5 }}>Laki-Laki</div>
                  </div>

                  <div style={{ display: "flex", alignItems: "center", cursor: "pointer", marginLeft: 20 }}
                    onClick={() => this.handleChange('trainer_jk', "Perempuan")}>
                    <div style={{
                      backgroundColor: (dataTrainer.trainer_jk === "Perempuan" ? "#1ab394" : "gray"), height: 20, width: 20, borderRadius: 20,
                      display: "flex", alignItems: "center", justifyContent: "center"
                    }}>
                      <i className={dataTrainer.trainer_jk === "Perempuan" ? "fa fa-check-circle" : "fa fa-circle"} style={{ fontSize: 15, color: "#FFF" }}></i>
                    </div>
                    <div style={{ marginLeft: 5 }}>Perempuan</div>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label><b>Foto</b></label>
                <div style={{ marginBottom: 5, marginTop: -5 }}>
                  <img alt="trainer" className="m-t-xs img-responsive" id="gambar_preview"
                    src={trainer_image}
                    style={{ height: 150, width: 100, borderRadius: 2, objectFit: "cover", overflow: 'hidden' }} />
                </div>

                <div className="progress progress-mini" style={{ marginBottom: 5 }}>
                  <div className="progress-bar progress-bar-primary" style={{ width: `${progress}%` }}
                    role="progressbar" aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100} />
                </div>

                <div className="custom-file">
                  <input id="foto" type="file" className="custom-file-input"
                    onChange={(e) => this.handleChange("trainer_image", e.target.files[0])} />
                  <label htmlFor="foto" className="custom-file-label">Choose file...</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
          <button type="button" className="btn btn-w-m btn-warning"
            onClick={() => this.props.history.goBack()}>
            <i className="fa fa-arrow-left" /> Kembali
          </button>
          <button type="button" className="btn btn-w-m btn-primary"
            style={{ marginLeft: 10 }} onClick={() => this.handleSave()}>
            <i className="fa fa-save" /> Save
          </button>
        </div>
      </div>
    )
  }

  pageView = () => {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox ">
            <div className="ibox-title">
              <h5>Form Trainer</h5>
            </div>
            <div className="ibox-content">
              {this.viewItemTrainer()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <Index {...this.props} pageView={this.pageView()} isLoading={this.state.isLoading} />
    );
  }
}
