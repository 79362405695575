import React, { Component } from 'react';
import Index from '../../index';
import Pagination from "react-js-pagination";
import DetailCourses from '../../../components/modal/detailCourses';
import CoursesController from './../../../controllers/courses';
import FilterBooking from '../../../components/formFilter';

const coursesController = new CoursesController()
const Swal = require('sweetalert2')

export default class Courses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      payload: {},
      dataCourses: [],
      coursesNama: [],
      trainerNama: [],
      dataCoursesLength: 0,
      activePage: 1,
      takeData: 5,
      showDetail: false,
      dataDetail: null
    };
  }

  componentDidMount = async () => {
    await this.getDataCourses()
    this.setState({ isLoading: false })
  }

  getDataCourses = async (activePage = null, payload = {}) => {
    if (!activePage) activePage = this.state.activePage
    let take = this.state.takeData
    let skip = (activePage - 1) * take

    payload.skip = skip
    payload.take = take
    payload.all = true

    let dataCourses = []
    let coursesNama = []
    let trainerNama = []
    let dataCoursesLength = 0
    let response = await coursesController.getCourses(payload)
    if (response.status) {
      dataCourses = response.data
      dataCoursesLength = response.total_data
    }
    response.courses_nama.forEach(element => {
      if (element.course_nama !== "") coursesNama.push(element.course_nama)
    });
    response.trainer_nama.forEach(element => {
      trainerNama.push(element.trainer_nama)
    });
    // console.log(response)
    // console.log(coursesNama)
    // console.log(trainerNama)
    this.setState({ dataCourses, dataCoursesLength, coursesNama, trainerNama })
  }

  handleChangeStatus = (items) => {
    let disabled = JSON.parse(JSON.stringify(items.course_deleted))
    if (disabled === "0") disabled = "1"
    else disabled = "0"

    this.setState({ showDetail: false })
    let configConfirm = {
      title: "Lanjut untuk mengubah status?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#18a689",
      confirmButtonText: "Lanjut",
    };

    Swal.fire(configConfirm).then(async (result) => {
      if (result.value) {
        this.setState({ isLoading: true })
        await coursesController.deleteCourses({ id: items.course_id, disabled })
        this.handleFilter()
      }
    });
  }

  handleDelete = (items) => {
    this.setState({ showDetail: false })
    let configConfirm = {
      title: "Lanjut untuk hapus data?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#18a689",
      confirmButtonText: "Lanjut",
    };

    Swal.fire(configConfirm).then(async (result) => {
      if (result.value) {
        this.setState({ isLoading: true })
        await coursesController.deleteCourses({ id: items.course_id, disabled: 2 })
        this.handleFilter("delete")
      }
    });
  }

  handleFilter = async (from = null) => {
    let activePage = this.state.activePage
    let dataCoursesLength = this.state.dataCoursesLength
    let takeData = this.state.takeData
    let payload = this.state.payload
    if (from && (from === "delete")) {
      let check = dataCoursesLength % takeData;
      if (check === 1) activePage -= 1
    } else if (from && from === "cancel") {
      payload = {}
      this.setState({ payload })
    }
    this.setState({ isLoading: true })
    await this.getDataCourses(activePage, payload)
    this.setState({ isLoading: false })
  }

  handlePageChange = async (pageNumber) => {
    let payload = this.state.payload
    this.setState({ isLoading: true, activePage: pageNumber });
    await this.getDataCourses(pageNumber, payload)
    this.setState({ isLoading: false })
  }

  getCurrency = (price, prefix) => {
    var number_string = price.toString().replace(/[^,\d]/g, '').toString(),
      split = number_string.split(','),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi),
      separator;

    if (ribuan) {
      separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
    return prefix === undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
  };

  viewItemCourses = () => {
    let dataCourses = this.state.dataCourses
    let dataCoursesLength = this.state.dataCoursesLength
    let activePage = this.state.activePage
    let takeData = this.state.takeData
    return (
      <div className="table-responsive" style={{ marginTop: 10 }}>
        <div>{`${dataCoursesLength} items`}</div>
        <table className="table table-hover table-bordered" style={{ fontSize: 12, marginTop: 10 }}>
          <thead>
            <tr>
              <th> No </th>
              <th> Courses Nama </th>
              <th> Trainer Nama </th>
              <th> Harga (Rp) </th>
              <th> Status </th>
              <th> Action </th>
            </tr>
          </thead>
          <tbody>
            {
              dataCourses.map((items, keys) => (
                <tr key={keys}>
                  <td> {keys + 1 + ((activePage - 1) * takeData)} </td>
                  <td> {items.course_nama} </td>
                  <td> {items.trainer_nama} </td>
                  <td className="text-right">
                    {this.getCurrency(items.course_harga - items.course_harga_diskon)}
                  </td>
                  <td>
                    <div className="switch">
                      <div className="onoffswitch">
                        <input type="checkbox" checked={items.course_deleted === "0" ? true : false}
                          className="onoffswitch-checkbox" id={items.course_id}
                          onChange={() => this.handleChangeStatus(items)} />
                        <label className="onoffswitch-label" htmlFor={items.course_id}>
                          <span className="onoffswitch-inner" />
                          <span className="onoffswitch-switch" />
                        </label>
                      </div>
                    </div>
                  </td>
                  <td style={{ width: 55 }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: 55 }}>
                      {/* <button className="btn btn-xs btn-info btn-outline"
                        style={{ width: 25, height: 25 }} onClick={() => this.setState({ dataDetail: items, showDetail: true })}>
                        <i className="fa fa-info" />
                      </button> */}
                      <button className="btn btn-xs btn-warning btn-outline"
                        style={{ width: 25, height: 25 }} onClick={() => this.props.history.push(`/courses/form?id=${items.course_id}`)}>
                        <i className="fa fa-pencil" />
                      </button>
                      <button className="btn btn-xs btn-danger btn-outline"
                        style={{ width: 25, height: 25 }} onClick={() => this.handleDelete(items)}>
                        <i className="fa fa-trash" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            }
            {
              dataCourses.length === 0 &&
              <tr>
                <td colSpan="7" style={{ fontWeight: "bold", textAlign: 'center' }}> Data kosong </td>
              </tr>
            }
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {
            dataCoursesLength !== dataCourses.length &&
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              hideDisabled
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.takeData}
              totalItemsCount={dataCoursesLength}
              pageRangeDisplayed={3}
              onChange={this.handlePageChange.bind(this)}
            />
          }
        </div>
      </div>
    )
  }

  pageView = () => {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox ">
            <div className="ibox-title" style={{
              display: "flex", alignItems: "center", justifyContent: "space-between"
            }}>
              <h5>Daftar Courses</h5>
              <button type="button" className="btn btn-w-m btn-primary"
                onClick={() => this.props.history.push("/courses/form")}
                style={{ position: "absolute", right: 20 }}>
                <i className="fa fa-plus" /> Tambah Courses
              </button>
            </div>
            <div className="ibox-content">
              <FilterBooking setting={["courses", "trainer"]} payload={this.state.payload}
                handleFilter={(from) => this.handleFilter(from)}
                dataCourses={this.state.coursesNama}
                dataTrainer={this.state.trainerNama} />
              {this.viewItemCourses()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <DetailCourses
          show={this.state.showDetail}
          onHide={() => this.setState({ showDetail: false })}
          handleEdit={(items) => this.props.history.push(`/courses/form?id=${items.course_id}`)}
          data={this.state.dataDetail} />
        <Index {...this.props} pageView={this.pageView()} isLoading={this.state.isLoading} />
      </div>
    );
  }
}
