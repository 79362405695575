import React, { Component } from 'react';
import Footer from '../components/layouts/footer.js';
import Navbar from '../components/layouts/navbar.js';
import Sidebar from '../components/layouts/sidebar.js';
import { LoopCircleLoading } from 'react-loadingg';

const loading = () => <div className="animated fadeIn pt-3 text-center" style={{ marginTop: 350 }}><LoopCircleLoading /></div>;

export default class Index extends Component {
  render() {
    return (
      <div>
        <div id="wrapper">
          <Sidebar />
          <div id="page-wrapper" className="gray-bg">
            <Navbar />
            <div className="wrapper wrapper-content animated fadeInRight">
              {this.props.isLoading ? loading() : this.props.pageView}
            </div>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
