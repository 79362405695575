import React, { Component } from 'react';
import Index from '../../index';
import SendMail from '../../../controllers/sendMail';
import PesertaController from './../../../controllers/peserta';
import CoursesController from './../../../controllers/courses';
import EventController from './../../../controllers/event';

const sendMail = new SendMail()
const pesertaController = new PesertaController()
const coursesController = new CoursesController()
const eventController = new EventController()
const Swal = require('sweetalert2')

export default class Broadcast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      bodyEmail: `Hai sahabat <b>Pengen Bisa</b>, ada yang lagi promo nih. Kuy langsung booking melalui aplikasi <b>Pengen Bisa</b>.<br/>
{listCourses}<br/>
{listEvent}<br/>
Ayo nunggu apa lagi, hanya di <b>Pengen Bisa</b>.`,
      activePage: 1,
      takeData: 5,
      isCourses: false,
      isEvent: false,
      isCutomer: false,
      dataCourses: [],
      dataEvent: [],
      listCustomer: '',
      selectCourses: {},
      selectEvent: {},
      progress: 0,
      isSend: false
    };
  }

  componentDidMount = async () => {
    await this.getData()
    this.setState({ isLoading: false })
  }

  getData = async (payload = {}) => {
    let getCourses = await coursesController.getCourses(payload)
    if(getCourses.status) {
      let dataCourses = []
      getCourses.data.forEach(items => {
        if(!dataCourses.find(o => {return o.course_nama === items.course_nama})){
          dataCourses.push(items)
        }
      });
      this.setState({dataCourses})
    }

    let getEvent = await eventController.getEvent(payload)
    if(getEvent.status) {
      let dataEvent = []
      getEvent.data.forEach(items => {
        if(!dataEvent.find(o => {return o.event_judul === items.event_judul})){
          dataEvent.push(items)
        }
      });
      this.setState({dataEvent})
    }
  }

  getCurrency = (price, prefix) => {
    var number_string = price.toString().replace(/[^,\d]/g, '').toString(),
      split = number_string.split(','),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi),
      separator;

    if (ribuan) {
      separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
    return prefix === undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
  };

  handleSend = async () => {
    let {
      bodyEmail, dataCourses, dataEvent, listCustomer,
      selectCourses, selectEvent
    } = this.state
    
    this.setState({isSend: true, progress: 0})
    Swal.showLoading()

    if(listCustomer !== '') {
      listCustomer = listCustomer.split(',')
    } else {
      listCustomer = []
      let getPeserta = await pesertaController.getPeserta()
      if(getPeserta.status) {
        getPeserta.data.forEach(items => {
          listCustomer.push(items.peserta_username)
        });
      }
    }

    let listCourses = []
    for (const key in selectCourses) {
      let check = dataCourses.find(o => {return o.course_id === key})
      if(check) {
        if (check.course_image.includes('assets/foto') || check.course_image.includes('assets/gambar')) {
          check.course_image = `https://www.pengenbisa.com/${check.course_image}`
        }
        listCourses.push(check)
      }
    }

    let listEvent = []
    for (const key in selectEvent) {
      let check = dataEvent.find(o => {return o.event_id === key})
      if(check) {
        if (check.event_image.includes('assets/foto') || check.event_image.includes('assets/gambar')) {
          check.event_image = `https://www.pengenbisa.com/${check.event_image}`
        }
        listEvent.push(check)
      }
    }

    for (let index = 0; index < listCustomer.length; index++) {
      let progress = ((index + 1) / listCustomer.length) * 100
      let payload = {
        fromAddresses: "pengenbisa.edu@gmail.com",
        toAddresses: listCustomer[index],
        subject: "PROMO KELAS & EVENT PENGEN BISA",
        body: bodyEmail,
        listCourses, listEvent,
        name: "PENGEN BISA",
        replyTo: "pengenbisa.edu@gmail.com",
      }
      await sendMail.sendSMTP(payload)
      this.setState({progress: progress.toFixed(2)})
    }
    this.setState({isSend: false})
    Swal.close()
  }

  getTemplateCard = (imagesItems, nameItems, priceItems, hostItems) => {
    return `<div style="
    display: flex; align-items: center; 
    margin-top: 5px; margin-bottom: 5px; 
    border: 1px solid; border-color: #DCDCDC;
    padding: 5px; border-radius: 5px;
  ">
    <img
      src="${imagesItems}"
      alt="${nameItems}" style="height: 70px; width: 60px; border-radius: 5px;" />
    <div style="margin-left: 10px; width: 100%;">
      <div style="color: #066C8E; font-weight: bold;">${nameItems}</div>
      <div style="font-size: 12px; color: #066C8E;">${hostItems}</div>
      <div style="
        background-color: #066C8E; height: 1px; 
        margin-top: 5px; margin-bottom: 5px; 
      "> </div>
      <div style="
        font-size: 12px; color: #fe8d02; 
        font-weight: bold;
      ">${Number(priceItems) > 0 ? this.getCurrency(priceItems) : 'Free'}</div>
    </div>
  </div>`
  }

  pageView = () => {
    let {
      isCourses, isEvent, isCutomer, bodyEmail, 
      dataCourses, dataEvent, listCustomer,
      selectCourses, selectEvent, progress,
      isSend
    } = this.state

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox ">
            <div className="ibox-title" style={{
              display: "flex", alignItems: "center", justifyContent: "space-between"
            }}>
              <h5>Broadcast</h5>
            </div>
            <div className="ibox-content">
              <div className="row">
                <div className="col-lg-12">
                  <label><b>Setting</b></label>
                  <label style={{
                    marginRight: 10,
                    display: "flex",
                    alignItems: "center"
                  }}> 
                    <input
                      style={{marginRight: 5}} 
                      type="checkbox" 
                      checked={isCourses} 
                      onClick={() => this.setState({isCourses: !isCourses})} 
                    /> 
                    SELECT COURSES 
                  </label>
                  {
                    isCourses && 
                    <div className="row" style={{
                      border: '1px solid #DCDCDC',
                      borderRadius: 5,
                      paddingTop: 10,
                      marginBottom: 10,
                      marginTop: 0,
                      marginLeft: 17,
                      marginRight: 0
                    }}>
                      {
                        dataCourses.map((items, key) => (
                          <div className="col-lg-4"> 
                            <label key={key} style={{
                              marginRight: 10,
                              display: "flex",
                              alignItems: "center"
                            }}> 
                              <input
                                style={{marginRight: 5}} 
                                type="checkbox" 
                                checked={selectCourses[items.course_id]}
                                onClick={() => {
                                  selectCourses[items.course_id] = !selectCourses[items.course_id]
                                  this.setState({selectCourses})
                                }} 
                              /> 
                              {items.course_nama}
                            </label>
                          </div>
                        ))
                      }
                    </div>
                  }
                  <label style={{
                    marginRight: 10,
                    display: "flex",
                    alignItems: "center"
                  }}> 
                    <input
                      style={{marginRight: 5}} 
                      type="checkbox" 
                      checked={isEvent} 
                      onClick={() => this.setState({isEvent: !isEvent})} 
                    /> 
                    SELECT EVENT
                  </label>
                  {
                    isEvent && 
                    <div className="row" style={{
                      border: '1px solid #DCDCDC',
                      borderRadius: 5,
                      paddingTop: 10,
                      marginBottom: 10,
                      marginTop: 0,
                      marginLeft: 17,
                      marginRight: 0
                    }}>
                      {
                        dataEvent.map((items, key) => (
                          <div className="col-lg-4"> 
                            <label key={key} style={{
                              marginRight: 10,
                              display: "flex",
                              alignItems: "center"
                            }}> 
                              <input
                                style={{marginRight: 5}} 
                                type="checkbox" 
                                checked={selectEvent[items.event_id]}
                                onClick={() => {
                                  selectEvent[items.event_id] = !selectEvent[items.event_id]
                                  this.setState({selectEvent})
                                }} 
                              /> 
                              {items.event_judul}
                            </label>
                          </div>
                        ))
                      }
                    </div>
                  }

                  <label style={{
                    marginRight: 10,
                    display: "flex",
                    alignItems: "center"
                  }}> 
                    <input
                      style={{marginRight: 5}} 
                      type="checkbox" 
                      checked={isCutomer} 
                      onClick={() => this.setState({isCutomer: !isCutomer})} 
                    /> 
                    SET CUSTOMER 
                  </label>
                  {
                    isCutomer &&
                    <div className="row" style={{
                      paddingTop: 10,
                      marginBottom: 10,
                      marginTop: -10,
                      marginLeft: 17,
                      marginRight: 0
                    }}>
                      <textarea 
                        value={listCustomer}
                        className="form-control" rows={2}
                        onChange={(e) => this.setState({listCustomer: e.target.value})} 
                      />
                      <small>
                        {`- Tanda koma (,) merupakan pemisah antar email`}
                      </small>
                    </div>
                  }
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label><b>Body Email</b></label>
                    <textarea 
                      value={bodyEmail}
                      className="form-control" rows={6}
                      onChange={(e) => this.setState({bodyEmail: e.target.value})} 
                    />
                    <small>
                      {`- Tanda {listCourses} untuk menampilkan list course yang di pilih`}
                    </small> <br/>
                    <small>
                      {`- Tanda {listEvent} untuk menampilkan list event yang di pilih`}
                    </small>
                  </div>
                </div>
              </div>
              <hr/>
              <button 
                type="button" 
                className="btn btn-w-m btn-primary"
                onClick={() => this.handleSend()}
                disabled={isSend}
              >
                <i className="fa fa-paper-plane-o" /> KIRIM
              </button>
              <hr/>
              {progress > 0 ? `Progress : ${progress}%` : ''}
              <div className="progress progress-mini" style={{ marginBottom: 5 }}>
                <div className="progress-bar progress-bar-primary" style={{ width: `${progress}%` }}
                  role="progressbar" aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100} />
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <Index {...this.props} pageView={this.pageView()} isLoading={this.state.isLoading} />
    );
  }
}
