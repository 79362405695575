import React, { Component } from 'react';
import Index from '../../index';
import { storage } from '../../../config/firebase';
import CoursesController from '../../../controllers/courses';

const coursesController = new CoursesController()

export default class FormCourses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      progress: 0,
      isEdit: false,
      dataTrainer: [],
      dataCourses: {
        course_nama: "",
        course_desc: "",
        course_harga: "",
        course_harga_diskon: "",
        course_jum_per: "",
        course_jenis_per: "",
        course_status: 1,
        course_type: "",
        course_image: "assets/foto/400x600.png",
        course_materi: [],
        course_materi_detail: [],
        course_trainer_id: "",
        course_json_materi: [
          { materi: "", detailMateri: "", materi_id: new Date().getTime() }
        ]
      },
      materi: [
        { materi: "", detailMateri: "", materi_id: new Date().getTime() }
      ],
    };
  }

  componentDidMount = async () => {
    const id = new URLSearchParams(this.props.location.search).get("id");
    this.getDataCourses(id)
  }

  getDataCourses = async (id) => {
    let payload = { take: 1, skip: 0 }
    if (id) payload.id = id
    let dataCourses = await coursesController.getCourses(payload)
    // console.log(dataCourses)
    if (dataCourses.status) {
      let dataTrainer = dataCourses.trainer_nama
      if (id) {
        dataCourses = dataCourses.data[0]
        let materi = []
        if (dataCourses.course_json_materi === "") {
          let titleMmateri = dataCourses.course_materi.split('"');
          let detailMateri = dataCourses.course_materi_detail.split('"');
          for (let i = 0; i < titleMmateri.length; i++) {
            if (i % 2 !== 0) {
              materi.push({
                materi: titleMmateri[i],
                detailMateri: detailMateri[i],
                materi_id: new Date().getTime() + i,
              });
            }
          }
        } else {
          materi = JSON.parse(dataCourses.course_json_materi)
        }
        this.setState({ dataCourses, materi, isEdit: true })
      }
      this.setState({ dataTrainer })
    }
    this.setState({ isLoading: false })
  }

  handleSave = async () => {
    this.setState({ isLoading: true })
    let dataCourses = this.state.dataCourses
    let materi = this.state.materi
    dataCourses.course_json_materi = JSON.stringify(materi)
    dataCourses.course_harga = parseInt(dataCourses.course_harga.replace(/\D/g, ""))
    dataCourses.course_harga_diskon = parseInt(dataCourses.course_harga_diskon.replace(/\D/g, ""))
    let isEdit = this.state.isEdit

    // console.log(dataCourses)
    let response = {}
    if (isEdit) response = await coursesController.updateCourses(dataCourses)
    else response = await coursesController.addCourses(dataCourses)

    if (response.status) this.props.history.goBack()
    this.setState({ isLoading: false })
  }

  convertToSlug = (nameCourses, nameFaile) => {
    if (nameCourses === "") nameCourses = nameFaile
    return nameCourses.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-');
  }

  handleChange = (field, value) => {
    let dataCourses = this.state.dataCourses
    if (field === "course_image") {
      let url_name = this.convertToSlug(dataCourses.course_nama, value.name)
      const uploadTask = storage.ref(`courses_image/${url_name}`).put(value)
      uploadTask.on("state_changed",
        snapshot => {
          const progress = Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100
          this.setState({ progress })
        }, error => {
          console.log(error)
        }, () => {
          storage.ref("courses_image").child(url_name)
            .getDownloadURL().then(url => {
              dataCourses[field] = url
              this.setState({ dataCourses })
            })
        })
    } else if (field === "course_trainer_id") {
      let dataTrainer = this.state.dataTrainer
      dataTrainer = dataTrainer.find(items => { return items.trainer_id === value })
      dataCourses[field] = value
      dataCourses['course_image'] = dataTrainer.trainer_image
      this.setState({ dataCourses })
    } else {
      dataCourses[field] = value
      this.setState({ dataCourses })
    }
  }

  handleAddMateri = (items) => {
    let materi = this.state.materi
    items = materi.push(items)
    this.setState({ materi })
  }

  handleReduceMateri = (materi_id) => {
    let materi = this.state.materi
    materi = materi.filter(items => { return items.materi_id !== materi_id })
    this.setState({ materi })
  }

  getCurrency = (price, prefix) => {
    var number_string = price.toString().replace(/[^,\d]/g, '').toString(),
      split = number_string.split(','),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi),
      separator;

    if (ribuan) {
      separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
    return prefix === undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
  };

  handleChangeMateri = (items, field, e) => {
    let materi = this.state.materi
    items[field] = e
    this.setState({ materi })
  }

  viewItemCourses = () => {
    let dataCourses = this.state.dataCourses
    let dataTrainer = this.state.dataTrainer
    let materi = this.state.materi

    return (
      <div>
        <div style={{ border: "1px solid #DCDCDC", borderRadius: 5, padding: 10 }}>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label><b>Nama</b></label>
                <input type="text" className="form-control" value={dataCourses.course_nama}
                  onChange={(e) => this.handleChange("course_nama", e.target.value)} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label><b>Harga (Rp)</b></label>
                <input type="text" className="form-control" value={this.getCurrency(dataCourses.course_harga, 'Rp. ')}
                  onChange={(e) => this.handleChange("course_harga", e.target.value)} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label><b>Diskon (Rp)</b></label>
                <input type="text" className="form-control" value={this.getCurrency(dataCourses.course_harga_diskon, 'Rp.')}
                  onChange={(e) => this.handleChange("course_harga_diskon", e.target.value)} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label><b>Trainer</b></label>
                <select value={dataCourses.course_trainer_id} className="form-control m-b"
                  onChange={(e) => this.handleChange("course_trainer_id", e.target.value)}>
                  <option value="">-- Pilih --</option>
                  {
                    dataTrainer.map((items, key) => (
                      <option key={key} value={items.trainer_id}>{items.trainer_nama}</option>
                    ))
                  }
                </select>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label><b>Jenis Pertemuan</b></label>
                <select value={dataCourses.course_jenis_per} className="form-control m-b"
                  onChange={(e) => this.handleChange("course_jenis_per", e.target.value)}>
                  <option value="">-- Pilih --</option>
                  <option value="Offline">Offline</option>
                  <option value="Online">Online</option>
                </select>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label><b>Type Courses</b></label>
                <select value={dataCourses.course_type} className="form-control m-b"
                  onChange={(e) => this.handleChange("course_type", e.target.value)}>
                  <option value="">-- Pilih --</option>
                  <option value="Skill">Kelas</option>
                  <option value="Mentoring Beasiswa">Mentoring Beasiswa</option>
                </select>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label><b>Jumlah Pertemuan</b></label>
                <input type="number" className="form-control" value={dataCourses.course_jum_per}
                  onChange={(e) => this.handleChange("course_jum_per", e.target.value)} />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <label><b>Deskripsi Courses</b></label>
                <textarea className="form-control" value={dataCourses.course_desc} rows={4}
                  onChange={(e) => this.handleChange("course_desc", e.target.value)} />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-group">
                <label><b>Materi</b></label>
                <div className="row">
                  {
                    materi.map((items, key) => (
                      <div key={key} className="col-lg-6" >
                        <div style={{
                          border: "1px solid #CDCDCD", borderRadius: 5, padding: 5, marginBottom: 10
                        }}>
                          <div style={{ position: "absolute", right: 20, top: 0, cursor: "pointer" }}
                            onClick={() => this.handleReduceMateri(items.materi_id)}>
                            <i className="fa fa-times" />
                          </div>
                          <small>Title</small>
                          <input type="text" className="form-control" value={items.materi} onChange={(e) => this.handleChangeMateri(items, 'materi', e.target.value)} />
                          <small>Detail</small>
                          <textarea className="form-control" rows={2} style={{ width: '100%' }} value={items.detailMateri} onChange={(e) => this.handleChangeMateri(items, 'detailMateri', e.target.value)} />
                        </div>
                      </div>
                    ))
                  }
                </div>

                <button type="button" className="btn btn-w-m btn-info add-pricing-list-item"
                  onClick={() => this.handleAddMateri({ materi: "", detailMateri: "", materi_id: new Date().getTime() })} style={{ marginTop: 10 }}>
                  <i className="fa fa-plus" /> Tambah Materi
                </button>
              </div>
            </div>

          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
          <button type="button" className="btn btn-w-m btn-warning"
            onClick={() => this.props.history.goBack()}>
            <i className="fa fa-arrow-left" /> Kembali
          </button>
          <button type="button" className="btn btn-w-m btn-primary"
            style={{ marginLeft: 10 }} onClick={() => this.handleSave()}>
            <i className="fa fa-save" /> Save
          </button>
        </div>
      </div>
    )
  }

  pageView = () => {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="ibox ">
            <div className="ibox-title">
              <h5>Form Courses</h5>
            </div>
            <div className="ibox-content">
              {this.viewItemCourses()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <Index {...this.props} pageView={this.pageView()} isLoading={this.state.isLoading} />
    );
  }
}
