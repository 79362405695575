import { Component } from 'react';
import fetchApi from '../fetchApi';

const encryptor = require('simple-encryptor')(process.env.REACT_APP_API_URL);
const account = encryptor.decrypt(JSON.parse(localStorage.getItem('account')));

const Swal = require('sweetalert2')
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

export default class PromotionController extends Component {
  getPromotion = async (payload) => {
    try {
      let url = `promotion`;
      let prefix = ""
      if (payload && Object.keys(payload).length > 0) {
        for (const key in payload) {
          prefix = `${prefix}${key}=${payload[key]}&`
        }
        prefix = prefix.substr(0, prefix.length - 1);
        url = `${url}?${prefix}`
      }
      // console.log(url)
      let response = await fetchApi("GET", url);
      // console.log(response)
      return response
    } catch (error) {
      console.log(error)
      return false
    }
  }

  updatePromotion = async (payload) => {
    try {
      payload.username = account.username
      let response = await fetchApi("PUT", 'promotion', payload);
      // console.log(response)
      if (response.status) {
        Toast.fire({ icon: 'success', title: response.message })
      } else {
        Toast.fire({ icon: 'error', title: response.message })
      }
      return response
    } catch (error) {
      console.log(error)
      return false
    }
  }

  deletePromotion = async (payload) => {
    try {
      payload.username = account.username
      let response = await fetchApi("DELETE", 'promotion', payload);
      // console.log(response)
      if (response.status) {
        Toast.fire({ icon: 'success', title: response.message })
      } else {
        Toast.fire({ icon: 'error', title: response.message })
      }
      return response
    } catch (error) {
      console.log(error)
      return false
    }
  }

  addPromotion = async (payload) => {
    try {
      payload.username = account.username
      let response = await fetchApi("POST", 'promotion', payload);
      // console.log(response)
      if (response.status) {
        Toast.fire({ icon: 'success', title: response.message })
      } else {
        Toast.fire({ icon: 'error', title: response.message })
      }
      return response
    } catch (error) {
      console.log(error)
      return false
    }
  }
}
